import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { getAll, type ResourceComment } from '@/api/comment';
import { commentsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchDocumentsComposableArgs = {
  resourceId: MaybeRef<number | null>,
  resourceType: MaybeRef<string>,
} & UseQueryOptions<ResourceComment[], ApiError>;

function useFetchComments({ resourceId, resourceType, ...options }: FetchDocumentsComposableArgs) {
  return useReactiveQuery({
    enabled: !!unref(resourceId) && !!unref(resourceType),
    queryKey: commentsKey(resourceId, resourceType),
    queryFn: () => getAll(unref(resourceType), unref(resourceId)).then((response) => response.data.comments),
    ...options,
  });
}

export { useFetchComments };
