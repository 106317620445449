<script setup lang="ts">
import { UserGroupIcon, ArrowPathRoundedSquareIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { format, formatDistance, formatDistanceToNow, parseISO } from 'date-fns';
import { ref } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';

defineProps<{
  groupLoanApplications: GroupLoanApplication[]
}>();

const filters = ref({
  // eslint-disable-next-line camelcase
  aasm_state: '',
});

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);

  return {
    // eslint-disable-next-line camelcase
    aasm_state: params.get('aasm_state') || '',
  };
}

const queryParams = getQueryParams();
filters.value = { ...filters.value, ...queryParams };

function getBadgeColor(application) {
  const colors = {
    'inviting': 'yellow',
    'draft': 'blue',
    'application_in_review': 'green',
    'in_kyc': 'pink',
    'kyc_in_review': 'pink',
    'contracts_signature_pending': 'pink',
    'contracts_signed': 'gray',
    'application_rejected': 'red',
  };

  return colors[application.aasmState] || 'gray';
}
</script>

<template>
  <div class="grid w-full max-w-3xl grid-cols-6 gap-x-4">
    <ul
      v-if="groupLoanApplications.length > 0"
      class="col-span-4 flex w-full flex-col gap-y-4"
    >
      <li
        v-for="groupLoanApplication in groupLoanApplications"
        :key="groupLoanApplication.id"
      >
        <a
          class="flex cursor-pointer flex-col rounded-md bg-gray-100 p-2 hover:bg-white"
          :href="`/reviews/group_loan_applications/${groupLoanApplication.id}`"
        >
          <div class="flex flex-row items-center justify-between gap-x-4">
            <span class="font-bold">
              {{ groupLoanApplication.group.name }}
            </span>
            <div class="flex flex-row items-center gap-x-1">
              <base-badge
                v-if="groupLoanApplication.group.promoter"
                :label="groupLoanApplication.group.promoter.firstName"
                color="gray"
                size="xs"
              />
              <base-badge
                :color="getBadgeColor(groupLoanApplication)"
                :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
                size="xs"
              />
            </div>
          </div>
          <span
            v-if="!['inviting', 'draft'].includes(groupLoanApplication.aasmState) &&
              groupLoanApplication.submittedAt"
            class="mt-2 text-xs text-gray-600"
          >
            Enviada el
            {{
              `${format(parseISO(groupLoanApplication.submittedAt), 'dd-MM-yyyy')}
                ${formatDistance(parseISO(groupLoanApplication.submittedAt), new Date(), { addSuffix: true })}`
            }}
          </span>
          <div class="mt-2 flex flex-row items-center gap-x-6">
            <span class="text-sm">
              💰 {{ $filters.toCurrency(groupLoanApplication.totalRequestedAmount) }}
            </span>
            <div class="flex flex-row items-center gap-x-1 text-sm">
              <UserGroupIcon class="h-3.5 w-3.5" />
              <span>
                {{ groupLoanApplication.group.membersAndInvitationsCount }}
              </span>
            </div>
            <div
              v-if="groupLoanApplication.cycle > 1"
              class="flex flex-row items-center gap-x-1 text-sm"
            >
              <ArrowPathRoundedSquareIcon class="h-4 w-4" />
              <span>
                Renovación
              </span>
            </div>
            <div
              v-if="['inviting', 'draft'].includes(groupLoanApplication.aasmState)"
              class="ml-auto flex flex-row items-center gap-x-1"
            >
              <ClockIcon
                class="h-3 w-3 text-primary-600/70"
              />
              <span class="mt-0.5 text-xs text-gray-700">
                Creado
                {{ formatDistanceToNow(new Date(groupLoanApplication.group.createdAt), { addSuffix: true }) }}
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <span
      v-else
      class="col-span-4 w-full text-center italic text-gray-600"
    >
      No hay solicitudes para revisar.
    </span>
    <form
      method="get"
      class="col-span-2 flex w-full flex-col gap-y-4"
    >
      <base-native-select
        v-model="filters.aasm_state"
        name="aasm_state"
        :options="[
          { value: 'application_rejected', label: 'Rechazada' },
          { value: 'inviting', label: 'Invitando' },
          { value: 'draft', label: 'Draft' },
          { value: 'application_in_review', label: 'Solicitud en revisión' },
          { value: 'in_kyc', label: 'En KYC' },
          { value: 'kyc_in_review', label: 'KYC en review' },
          { value: 'contracts_signature_pending', label: 'Firmando contratos' },
          { value: 'contracts_signed', label: 'Contratos firmados' },
          { value: 'all', label: 'Todas' },
        ]"
      />
      <base-button
        type="submit"
        text="Filtrar"
        variant="white"
      />
    </form>
  </div>
</template>
