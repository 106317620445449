import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { index, show, type Document } from '@/api/document';
import { documentKey, documentsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchDocumentsComposableArgs = {
  documentableId: MaybeRef<number>,
  documentableType: MaybeRef<string>,
} & UseQueryOptions<Document[], ApiError>;

function useFetchDocuments({ documentableId, documentableType, ...options }: FetchDocumentsComposableArgs) {
  return useReactiveQuery({
    queryKey: documentsKey(documentableId, documentableType),
    queryFn: () => index({
      documentableId: unref(documentableId), documentableType: unref(documentableType),
    }).then((response) => response.data.documents),
    ...options,
  });
}

type FetchDocumentComposableArgs = {
  id: MaybeRef<number>,
} & UseQueryOptions<Document, ApiError>;

function useFetchDocument({ id, ...options }: FetchDocumentComposableArgs) {
  return useReactiveQuery({
    queryKey: documentKey(id),
    queryFn: () => show(unref(id)).then((response) => response.data.document),
    ...options,
  });
}

export { useFetchDocuments, useFetchDocument };
