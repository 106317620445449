<script setup lang="ts">
import { computed } from 'vue';

import type { Group, GroupConnection, GroupConnections } from '@/api/group';
import BaseHeaderTable from '@/components/base-header-table.vue';

const props = defineProps<{
  groupConnections: GroupConnections,
  group: Group;
}>();

// eslint-disable-next-line complexity
function getConnectionLabel(connection: GroupConnection) {
  switch (connection.type) {
  case 'is_member_in_other_group':
    return `Es miembro en ${connection.group.name}`;
  case 'reference_to_active_member':
    return `Ingresó como referencia a miembro activo en ${connection.group.name}`;
  case 'reference_to_inactive_member':
    return `Ingresó como referencia a miembro inactivo en ${connection.group.name}`;
  case 'same_reference':
    return `Tiene misma referencia que un miembro de ${connection.group.name}`;
  case 'group_member_is_reference_in_external_group':
    return `Es referencia en ${connection.group.name}`;
  case 'same_bank_account':
    return `Tiene misma cuenta bancaria que un miembro de ${connection.group.name}`;
  case 'same_curp':
    return `Tiene misma CURP que un miembro de ${connection.group.name}`;
  default:
    return connection.type;
  }
}

function buildApplicationAnchorTag(id: number) {
  return `
    <a
      class="text-blue-400"
      target="_blank"
      href="/reviews/group_loan_applications/${id}"
    >
      Ir a solicitud
    </a>`;
}

const rows = computed(() => {
  const membersWithConnections = Object.keys(props.groupConnections)
    .map(memberId => Number(memberId))
    .filter(memberId => (props.groupConnections[memberId] || []).length > 0);

  const allGroupMembers = [...props.group.members, ...props.group.inactiveMembers];

  return membersWithConnections.map(memberId => [
    ...props.groupConnections[memberId].map(connection => [
      connection.group.name,
      allGroupMembers.find(member => member.id === Number(memberId))?.user?.fullName,
      getConnectionLabel(connection),
      connection.groupLoanApplicationId && buildApplicationAnchorTag(connection.groupLoanApplicationId),
    ]),
  ]).flat();
});
</script>

<template>
  <div class="flex flex-col rounded-lg bg-white px-4 py-6 sm:px-6 lg:px-8">
    <base-header-table
      v-if="Object.values(groupConnections).find(connection => connection.length > 0)"
      size="sm"
      title="Conexiones del grupo"
      :headers="['Grupo', 'Relación con', 'Tipo', 'Solicitud'] as const"
      :rows="rows as string[][]"
    />
    <span
      v-else
      class="ml-4 italic text-gray-700"
    >
      No hay conexiones con otros grupos
    </span>
  </div>
</template>
