<script setup>
import { useMutation } from '@tanstack/vue-query';
import { reactive, computed, ref, watch } from 'vue';
import { object, string } from 'yup';

import * as copomexApi from '@/api/copomex';
import * as groupMemberLoanApplicationApi from '@/api/groupMemberLoanApplication';
import * as validaCurpApi from '@/api/validaCurp';
import BaseAlert from '@/components/base-alert.vue';
import useUIStore from '@/stores/ui';

const textFieldSchema = string().required('Este campo es obligatorio');

const props = defineProps({
  formId: { type: Number, required: true },
  validated: { type: Boolean, default: false },
  user: { type: Object, required: true },
});

const uiStore = useUIStore();

const colonies = ref([]);
const form = reactive({
  curp: props.user.identificationNumber,
  street: props.user.street,
  externalNumber: props.user.externalNumber,
  internalNumber: props.user.internalNumber,
  colony: props.user.colony,
  municipality: props.user.municipality,
  postalCode: props.user.postalCode,
  state: props.user.state,
});

const {
  mutate: validateCurp,
  isPending: isValidatingCurp,
  isSuccess: isCurpValid,
  reset: resetCurpMutation,
} = useMutation({
  mutationFn: () => validaCurpApi.validateCurp(form.curp),
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al validar la CURP',
      type: 'error',
      position: 'bottom',
    });
  },
});

const {
  mutate: fetchPostalCodeData,
  isPending: isFetchingPostalCodeData,
  isSuccess: isPostalCodeValid,
  reset: resetPostalCodeMutation,
} = useMutation({
  mutationFn: () => copomexApi.getCpInfo(form.postalCode).then(response => response.data),
  onSuccess: (data) => {
    form.municipality = data.municipality;
    colonies.value = data.colonies;
    form.state = data.state;
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al obtener los datos del código postal',
      type: 'error',
      position: 'bottom',
    });
  },
});

watch(() => form.curp, resetCurpMutation);
watch(() => form.postalCode, resetPostalCodeMutation);

const schema = computed(() => {
  const baseSchema = object().shape({
    curp: textFieldSchema,
  });

  if (isCurpValid) {
    baseSchema.shape({
      street: textFieldSchema,
      externalNumber: textFieldSchema,
      internalNumber: textFieldSchema,
      colony: textFieldSchema,
      municipality: textFieldSchema,
      postalCode: textFieldSchema,
    });
  }

  return baseSchema;
});

const { mutate: submit, isSuccess, isPending: isSubmitting } = useMutation({
  mutationFn: () => groupMemberLoanApplicationApi.updateCurpAndAddress(props.formId, form),
  onSuccess: () => {
    uiStore.toast({
      message: 'Datos guardados correctamente',
      type: 'success',
      position: 'bottom',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Ocurrió un error al guardar los datos',
      type: 'error',
      position: 'bottom',
    });
  },
});

function handleSubmit() {
  if (!isCurpValid.value) {
    validateCurp();

    return;
  } else if (!isPostalCodeValid.value) {
    fetchPostalCodeData();

    return;
  }

  submit();
}
</script>

<template>
  <v-form
    v-if="!isSuccess && !validated"
    class="flex w-full max-w-xl flex-col"
    :validation-schema="schema"
    @submit="() => handleSubmit()"
  >
    <base-short-text-input
      v-model="form.curp"
      name="curp"
      label="CURP"
      description="Puedes encontrar tu CURP en tu INE"
      :loading="isValidatingCurp"
      :status-text="isValidatingCurp ? 'Validando CURP...' : ''"
    />
    <div v-if="isCurpValid">
      <base-short-text-input
        v-model="form.street"
        name="street"
        label="Calle"
        class="mt-2"
      />
      <base-short-text-input
        v-model="form.externalNumber"
        name="externalNumber"
        label="Número exterior"
        class="mt-2"
      />
      <base-short-text-input
        v-model="form.internalNumber"
        name="internalNumber"
        label="Número interior"
        class="mt-2"
      />
      <base-short-text-input
        v-model="form.postalCode"
        class="mt-2"
        name="postalCode"
        label="Código postal"
        :loading="isFetchingPostalCodeData"
        :status-text="isFetchingPostalCodeData ? 'Buscando código postal...' : ''"
      />
    </div>
    <div v-if="isPostalCodeValid">
      <base-native-select
        v-model="form.colony"
        class="mt-2"
        name="colony"
        label="Colonia"
        :options="colonies.map(colony => ({ label: colony, value: colony }))"
      />
      <base-short-text-input
        v-model="form.municipality"
        class="mt-2"
        name="municipality"
        label="Municipio"
        disabled
      />
    </div>
    <base-button
      text="Guardar"
      type="submit"
      class="mt-8"
      :loading="isSubmitting"
    />
  </v-form>
  <base-alert
    v-else
    type="success"
    message="Listo, ya puedes continuar con tu solicitud a través de WhatsApp"
  />
</template>
