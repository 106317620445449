<script setup>
import {
  BanknotesIcon,
  UserIcon,
  BriefcaseIcon,
  IdentificationIcon,
} from '@heroicons/vue/20/solid';
import { useMutation } from '@tanstack/vue-query';
import { useLocalStorage } from '@vueuse/core';
import { ref, inject, nextTick } from 'vue';

import * as groupMemberLoanApplicationApi from '@/api/groupMemberLoanApplication';
import CreditSimulator from '@/components/credit-simulator.vue';
import ConfirmPreviousApplicationData from
  '@/components/group-member-loan-application/confirm-previous-application-data.vue';
import FormAddressAndAbout from '@/components/group-member-loan-application/form-address-and-about.vue';
import FormEconomicActivity from '@/components/group-member-loan-application/form-economic-activity.vue';
import FormPersonalInformation from '@/components/group-member-loan-application/form-personal-information.vue';
import GroupMemberLoanApplicationAuthorizationFormModal from
  '@/components/group-member-loan-application-authorization-form-modal.vue';
import useMultiStepForm from '@/composables/useMultiStepForm.js';
import useUIStore from '@/stores/ui';
import { currentUserKey } from '@/utils/keys';

const props = defineProps({
  groupMemberLoanApplication: { type: Object, required: true },
  form: { type: Object, required: true },
  president: { type: Object, required: true },
});

const formStepSections = [
  ['loanSimulation'],
  ['personalInformation'],
  ['address', 'about'],
  ['entrepreneurship', 'economicActivity'],
];

const steps = [
  { name: 'Monto del crédito', icon: BanknotesIcon },
  { name: 'Datos de identificación', icon: IdentificationIcon },
  { name: 'Datos personales', icon: UserIcon },
  { name: 'Actividad económica', icon: BriefcaseIcon },
];

const currentUser = inject(currentUserKey);
const uiStore = useUIStore();

const DEFAULT_REPAYMENT_PERIODS = 16;
const repaymentPeriods = ref(DEFAULT_REPAYMENT_PERIODS);

const wantsToEditExistingApplication = useLocalStorage(
  `${props.groupMemberLoanApplication.publicId}-edit-existing-application`,
  false,
);

function handleEditEvent() {
  wantsToEditExistingApplication.value = true;
  nextTick(() => {
    window.scrollTo(0, 0);
  });
}

const {
  currentStep,
  isLastStep,
  storedForm,
  moveToNextStep,
  higherCurrentStep,
} = useMultiStepForm(
  props.form,
  `groupMemberLoanApplication-${props.groupMemberLoanApplication.publicId}`,
  formStepSections,
);

const {
  mutate: submitApplication,
  isSuccess: isApplicationSubmitSuccess,
} = useMutation({
  mutationFn: groupMemberLoanApplicationApi.submit,
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al enviar la solicitud',
      type: 'error',
      position: 'bottom',
    });
  },
});

const isAuthorizationPending = ref(true);
const showAuthorizationModal = ref(false);

function submit() {
  if (isAuthorizationPending.value) {
    showAuthorizationModal.value = true;
  } else {
    const form = { ...storedForm.value };
    form.sections.personalInformation.fields.birthDate.value =
        new Date(form.sections.personalInformation.fields.birthDate.value).toISOString();

    submitApplication({ form, repaymentPeriods: repaymentPeriods.value });
  }
}

function handleNextStep() {
  if (isLastStep.value) {
    submit();
  } else {
    moveToNextStep();
  }
}
</script>

<template>
  <div class="flex w-full flex-col">
    <base-back-button
      href="/"
    />
    <div
      v-if="isApplicationSubmitSuccess"
      class="mt-4 flex w-full flex-col rounded-lg bg-white px-4 py-6 sm:rounded-xl sm:px-6 sm:py-10"
    >
      <div class="flex flex-row">
        ✅
        <span class="ml-2 font-bold">
          Tu solicitud fue enviada
        </span>
      </div>
      <p class="mt-8 text-sm text-gray-800">
        Tu solicitud será revisada una vez que todos los integrantes del grupo
        completen su solicitud. Ya que la envíen, recibirán el resultado de su
        aprobación de crédito por WhatsApp.
      </p>
      <base-button
        class="mt-10"
        text="Volver al inicio"
        href="/"
      />
    </div>
    <div
      v-else-if="wantsToEditExistingApplication || groupMemberLoanApplication.cycle === 1"
      class="flex w-full flex-col"
    >
      <base-horizontal-progress-bar
        :steps="steps.length"
        :current-step="higherCurrentStep"
        :current-step-title="isApplicationSubmitSuccess ? '' : steps[currentStep].name"
      >
        <template #currentStepIcon>
          <component
            :is="steps[currentStep].icon"
            class="h-full w-full fill-current text-white"
          />
        </template>
      </base-horizontal-progress-bar>
      <div class="mt-10">
        <div
          v-if="currentStep === 0"
          class="flex flex-col gap-y-6"
        >
          <credit-simulator
            v-model:loan="storedForm.sections.loanSimulation.fields.amount.value"
            v-model:payments="repaymentPeriods"
            :show-payments-options="currentUser.id === president.id"
            class="rounded-lg"
          />
          <base-button
            text="Continuar"
            @click="handleNextStep"
          />
        </div>
        <form-personal-information
          v-if="currentStep === 1"
          v-model:first-name="storedForm.sections.personalInformation.fields.firstName.value"
          v-model:paternal-surname="storedForm.sections.personalInformation.fields.paternalSurname.value"
          v-model:maternal-surname="storedForm.sections.personalInformation.fields.maternalSurname.value"
          v-model:email="storedForm.sections.personalInformation.fields.email.value"
          v-model:sex="storedForm.sections.personalInformation.fields.sex.value"
          v-model:birth-date="storedForm.sections.personalInformation.fields.birthDate.value"
          v-model:identification-number="storedForm.sections.personalInformation.fields.identificationNumber.value"
          @submit="handleNextStep"
        />
        <form-address-and-about
          v-else-if="currentStep === 2"
          v-model:street="storedForm.sections.address.fields.street.value"
          v-model:external-number="storedForm.sections.address.fields.externalNumber.value"
          v-model:internal-number="storedForm.sections.address.fields.internalNumber.value"
          v-model:colony="storedForm.sections.address.fields.colony.value"
          v-model:municipality="storedForm.sections.address.fields.municipality.value"
          v-model:postal-code="storedForm.sections.address.fields.postalCode.value"
          v-model:state="storedForm.sections.address.fields.state.value"
          v-model:civil-status="storedForm.sections.about.fields.civilStatus.value"
          v-model:education="storedForm.sections.about.fields.education.value"
          @submit="handleNextStep"
        />
        <form-economic-activity
          v-else-if="currentStep === 3"
          v-model:employee="storedForm.sections.economicActivity.fields.employee.value"
          v-model:business-type="storedForm.sections.economicActivity.fields.businessType.value"
          v-model:dependents="storedForm.sections.economicActivity.fields.dependents.value"
          v-model:how-long-ago-business-started="storedForm.sections.economicActivity.fields.howLongAgoBusinessStarted.value"
          v-model:income="storedForm.sections.economicActivity.fields.income.value"
          v-model:expenses="storedForm.sections.economicActivity.fields.expenses.value"
          v-model:payment-abilities="storedForm.sections.economicActivity.fields.paymentAbilities.value"
          @submit="handleNextStep"
        />
      </div>
    </div>
    <confirm-previous-application-data
      v-else
      v-model:loan="storedForm.sections.loanSimulation.fields.amount.value"
      v-model:payments="repaymentPeriods"
      :show-payments-options="currentUser.id === president.id"
      :form="storedForm"
      @confirm="submit"
      @edit="handleEditEvent"
    />
  </div>
  <group-member-loan-application-authorization-form-modal
    :show="showAuthorizationModal"
    :group-member-loan-application="groupMemberLoanApplication"
    @submit="isAuthorizationPending = false; showAuthorizationModal = false; submit()"
    @close="showAuthorizationModal = false"
  />
</template>
