import { format as originalFormat, formatDistanceToNow } from 'date-fns';
import humps from 'humps';

function toCurrency(value: number, options = {}): string | number {
  if (!Number(value) && value !== 0) {
    return value;
  }

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
    ...options,
  });

  return formatter.format(value);
}

function formatNumber(
  value: number,
  options: Partial<Intl.NumberFormatOptions> & { separator?: string } = {},
): string {
  const { separator = ',', minimumFractionDigits = 0 } = options;

  if (!Number(value) && value !== 0) {
    return value.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits, ...options });

  return formatter.format(value).replaceAll(',', separator);
}

function abbreviateNumber(value: number): string {
  if (!Number(value) && value !== 0) {
    return value.toString();
  }

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });

  return formatter.format(value);
}

function formatDate(dateString: string, formatString: string) {
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object - remember to subtract 1 from month as JS months start at 0
  const date = new Date(year, month - 1, day);

  return originalFormat(date, formatString);
}

function formatTime(time: string, formatString: string) {
  return originalFormat(new Date(time), formatString);
}

function formatRelativeDate(time: string) {
  // eslint-disable-next-line no-magic-numbers
  const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000; // milliseconds in one week
  const date = new Date(time);

  const now = new Date();
  if (now.getTime() - date.getTime() < ONE_WEEK_IN_MS) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return originalFormat(date, 'dd-MM-yyyy');
}

function camelizeKeys(object: object): object {
  return humps.camelizeKeys(object);
}

export {
  toCurrency,
  formatNumber,
  abbreviateNumber,
  formatDate,
  formatTime,
  formatRelativeDate,
  camelizeKeys,
};
