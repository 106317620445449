import {
  type BaseGroupLoanApplication,
  type GroupLoanApplication,
} from '@/api/groupLoanApplication';
import type {
  GroupMemberLoanApplicationPreApprovalFormDataEntries,
} from '@/api/groupMemberLoanApplication/reviews';
import { api } from '@/api/index';

type AasmStateEvent = 'pass_application_to_review'
  | 'reject_application'
  | 'start_kyc'
  | 'review_kyc'
  | 'accept_kyc'
  | 'all_contracts_signed'

interface UpdatePayload extends Partial<BaseGroupLoanApplication> {
  aasmStateEvent: AasmStateEvent;
}

interface PreApprovePayload {
  'group_member_loan_application': {
    [key: number]: GroupMemberLoanApplicationPreApprovalFormDataEntries;
  }
}

export type { UpdatePayload, PreApprovePayload };

const basePath = '/api/internal/reviews/group_loan_applications';

function generateUnsignedContracts(id: number) {
  return api.post<{ groupLoanApplication: GroupLoanApplication }>(`${basePath}/${id}/unsigned_contracts`);
}

function preApprove(id: number, body: PreApprovePayload) {
  return api.post<void>(`${basePath}/${id}/pre_approvals`, body);
}

function update<TPayload = UpdatePayload>(id: number, groupLoanApplication: TPayload) {
  return api.put<{ groupLoanApplication: GroupLoanApplication }>(
    `${basePath}/${id}`,
    { groupLoanApplication },
  );
}

export { generateUnsignedContracts, preApprove, update };
