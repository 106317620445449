<script setup>
import { format } from 'date-fns';
import { ref, computed, inject } from 'vue';

import BaseCard from '@/components/base-card.vue';
import LoanRepaymentTimeline from '@/components/loan-repayment-timeline.vue';
import { toCurrency } from '@/utils/filters';
import { currentUserKey } from '@/utils/keys';

const props = defineProps({
  group: { type: Object, required: true },
  groupLoan: { type: Object, required: true },
  groupMemberLoans: { type: Array, required: true },
  currentUserMemberLoan: { type: Object, required: true },
  currentUserMember: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);

const loanInfo = computed(() => [
  {
    label: 'Pago por cada mil',
    value: toCurrency(props.currentUserMemberLoan.costPerThousand),
  },
  {
    label: 'Fecha del primer pago',
    value: format(new Date(props.groupLoan.firstPayinDate), "d 'de' MMMM, yyyy"),
  },
  {
    label: 'Fecha del último pago',
    value: format(new Date(props.groupLoan.lastPayinDate), "d 'de' MMMM, yyyy"),
  },
]);

const selectedGroupForm = ref(null);
function submitSelectedGroupForm(event) {
  event.target.form.submit();
}

const nextPayment = props.groupLoan.loanPayments
  .filter((payment) => !payment.paid)
  .sort((a, b) => (new Date(a.dueDate)).getTime() - (new Date(b.dueDate)).getTime())[0];
</script>

<template>
  <div class="flex w-full flex-col items-center gap-y-6 sm:gap-y-10">
    <v-form-native-wrapper
      v-if="currentUser.activeGroups.length > 1"
      ref="selectedGroupForm"
      action="/users/selected_groups"
      method="patch"
      class="w-full"
    >
      <base-native-select
        name="user[selected_group_id]"
        :options="currentUser.activeGroups.map(group => ({ label: group.name, value: group.id }))"
        :value="currentUser.selectedGroup.id"
        @change="(value) => submitSelectedGroupForm(value)"
      />
    </v-form-native-wrapper>
    <v-form-native-wrapper
      v-if="groupLoan.meetsConditionsForRenewal"
      action="/group_loan/renewals"
      method="POST"
      class="w-full"
    >
      <div class="flex w-full flex-row rounded-lg border border-gray-200 bg-white">
        <div class="flex flex-col gap-y-4 p-4">
          <span class="text-sm font-medium">
            ¡Felicidades! 🥳
          </span>
          <p class="text-xs text-gray-600">
            {{ groupLoan.canRenew
              ? 'Tu grupo ha cumplido con las condiciones para renovar su préstamo.'
              : `Tu grupo ha cumplido con las condiciones para renovar su préstamo. Cuando hayan
                completado todos su pagos podrán renovar, si así lo desean.` }}
          </p>
        </div>
        <button
          v-if="groupLoan.canRenew && currentUserMember.role === 'president'"
          type="submit"
          class="ml-auto flex flex-col rounded rounded-l-none text-sm font-medium text-primary-600 ring-primary-600 focus:ring"
        >
          <div class="my-4 flex grow items-center justify-center border-l border-gray-300 px-4">
            <span>
              Renovar
            </span>
          </div>
        </button>
      </div>
    </v-form-native-wrapper>
    <div class="flex w-full flex-col gap-6 md:flex-row">
      <base-card
        :title="`Próximo pago ${group.name}`"
        class="w-full md:w-2/3"
      >
        <div class="flex flex-col">
          <div class="flex flex-col gap-y-5">
            <div class="flex flex-row gap-x-1">
              <span class="text-sm">
                🗓️
              </span>
              <div class="flex flex-col gap-y-2">
                <span class="text-sm font-semibold text-gray-700">
                  {{ $filters.formatDate(nextPayment.dueDate, "EEEE dd 'de' MMMM") }}
                </span>
                <span class="text-xs text-gray-600">
                  recuerda que se debe reunir la ficha el día anterior.
                </span>
              </div>
            </div>
            <span class="text-sm text-gray-700">
              💸 La ficha completa es de <b>{{ $filters.toCurrency(groupLoan.repaymentAmount) }}</b> pesos.
            </span>
          </div>
          <table class="mt-8">
            <thead class="border-b border-gray-300">
              <tr>
                <th class="py-3.5 text-left text-xs">
                  Integrantes
                </th>
                <th class="py-3.5 text-left text-xs">
                  Pago
                </th>
              </tr>
            </thead>
            <tbody class="pt-10">
              <tr
                v-for="groupMemberLoan in groupMemberLoans"
                :key="groupMemberLoan.id"
              >
                <td class="flex flex-row gap-x-2 pt-4">
                  <base-avatar
                    :user="groupMemberLoan.groupMember.user"
                    size="sm"
                  />
                  <div
                    class="flex flex-col gap-y-1 text-xs"
                  >
                    <span class="text-xs font-medium">
                      {{ groupMemberLoan.groupMember.user.fullName }}
                    </span>
                    <span class="text-xs text-gray-700">
                      {{ groupMemberLoan.groupMember.humanRole }}
                    </span>
                  </div>
                </td>
                <td class="pt-4 text-sm text-gray-700">
                  {{ $filters.toCurrency(groupMemberLoan.repaymentAmount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-12 flex flex-col gap-y-2">
            <span class="text-xs text-gray-700">
              Próximos pagos
            </span>
            <loan-repayment-timeline
              :schedule="groupLoan.repaymentSchedule"
            />
          </div>
        </div>
      </base-card>
      <base-card
        title="Información de tu crédito"
        class="w-full self-start md:w-1/3"
      >
        <div class="flex flex-col gap-y-10">
          <div class="flex flex-col gap-y-2">
            <span class="text-xs font-medium text-gray-600">
              Monto aprobado
            </span>
            <div class="flex flex-row items-center justify-between gap-x-4">
              <span class="text-sm font-bold text-gray-800">
                💰 {{ $filters.toCurrency(currentUserMemberLoan.principal) }}
              </span>
              <base-badge
                v-if="groupLoan.aasmState === 'disbursing'"
                color="yellow"
                label="Desembolsando"
                size="sm"
              />
              <base-badge
                v-else
                color="green"
                label="Depositado"
                size="sm"
              />
            </div>
          </div>
          <div class="h-px w-full rounded-full bg-gray-100" />
          <div class="flex flex-col gap-y-6">
            <div
              v-for="info in loanInfo"
              :key="info.label"
              class="flex flex-col gap-y-0.5"
            >
              <span class="text-xs text-gray-600">
                {{ info.label }}
              </span>
              <span class="text-sm font-medium text-gray-800">
                {{ info.value }}
              </span>
            </div>
          </div>
          <base-button
            text="Descargar mi contrato"
            variant="white"
            :href="currentUserMemberLoan.contractUrl"
            target="_blank"
            size="sm"
          />
        </div>
      </base-card>
    </div>
  </div>
</template>
