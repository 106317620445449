import { api } from '@/api';
import type { IUser } from '@/api/currentUser';

interface ResourceComment {
  id: number;
  body: string;
  resourceId: number;
  resourceType: string;
  createdAt: string;
  authorId: IUser['id'];
  author: IUser;
}

function getAll(resourceType: string, resourceId: null | number = null) {
  const path = '/api/internal/comments';

  return api<{ comments: ResourceComment[] }>({
    params: { 'resource_id': resourceId, 'resource_type': resourceType },
    url: path,
  });
}

type CreateCommentBody = Pick<ResourceComment, 'body' | 'resourceId' | 'resourceType'> & {
  attachmentIds?: number[];
};
function create(body: CreateCommentBody) {
  const path = '/api/internal/comments';

  return api<{ comment: ResourceComment }>({
    method: 'post',
    data: body,
    url: path,
  });
}

export type { ResourceComment, CreateCommentBody };

export { getAll, create };
