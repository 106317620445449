<script setup lang="ts">
import { ref } from 'vue';

import { useUpdateCurrentUser, useUpdateCurrentUserAvatar } from '@/api/currentUser/mutations';
import useUIStore from '@/stores/ui';
import useUserStore from '@/stores/user';

interface Props {
  size: 'sm' | 'md' | 'lg' | 'xl';
}
withDefaults(defineProps<Props>(), {
  size: 'md',
});

const userStore = useUserStore();
const uiStore = useUIStore();

const addAvatarMutation = useUpdateCurrentUserAvatar({
  onSuccess: (user) => {
    userStore.setCurrentUser(user);
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al actualizar tu avatar',
      type: 'error',
      duration: 5000,
      position: 'bottom',
    });
  },
});

const updateUserMutation = useUpdateCurrentUser({
  onSuccess: (user) => {
    userStore.setCurrentUser(user);
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al actualizar tu avatar',
      type: 'error',
      duration: 5000,
      position: 'bottom',
    });
  },
});

const inputRef = ref<HTMLElement | null>(null);
function handleFileChange(fileEvent: Event) {
  const target = fileEvent.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    addAvatarMutation.mutate(target.files[0]);
  }
}

</script>
<template>
  <div class="flex flex-col gap-y-4">
    <span class="text-sm font-medium">
      Foto de perfil
    </span>
    <div class="flex flex-row items-center gap-x-4">
      <base-avatar
        :user="userStore.currentUser"
        :size="size"
      />
      <input
        ref="inputRef"
        class="hidden"
        type="file"
        accept="image/*"
        @change="handleFileChange"
      >
      <div class="flex flex-col gap-y-2">
        <base-button
          type="button"
          size="sm"
          text="Cambiar foto"
          :loading="addAvatarMutation.isPending"
          @click="$refs.inputRef.click()"
        />
        <base-button
          v-if="userStore.currentUser?.avatarUrl"
          type="button"
          size="sm"
          variant="red"
          text="Eliminar foto"
          :loading="updateUserMutation.isPending"
          @click="updateUserMutation.mutate({ removeAvatar: true })"
        />
      </div>
    </div>
  </div>
</template>
