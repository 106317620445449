import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';

// eslint-disable-next-line complexity
export function groupMemberLoanApplicationBadgeColor(aasmState: GroupMemberLoanApplication['aasmState']) {
  switch (aasmState) {
  case 'draft':
    return 'gray';
  case 'submitted':
    return 'green';
  case 'in_kyc':
    return 'blue';
  case 'kyc_submitted':
    return 'green';
  case 'contract_signature_pending':
    return 'yellow';
  case 'contract_signed':
    return 'green';
  case 'rejected':
    return 'red';
  default:
    return 'gray';
  }
}
