import { type MutationOptions, useQueryClient } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { create, destroy, type Document, type CreateDocumentFormData } from '@/api/document';
import { DOCUMENTS_BASE_KEY } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type UseCreateDocumentComposableArgs = MutationOptions<Document, ApiError, CreateDocumentFormData>

function useCreateDocument(options: UseCreateDocumentComposableArgs = {}) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: (body) => create(body).then((response) => response.data.document),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: DOCUMENTS_BASE_KEY });
    },
  });
}

type UseDestroyDocumentComposableArgs = MutationOptions<void, ApiError, MaybeRef<Document['id']>>

function useDestroyDocument(options: UseDestroyDocumentComposableArgs = {}) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: (documentId) => destroy(unref(documentId)).then((response) => response.data),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: DOCUMENTS_BASE_KEY });
    },
  });
}

export { useCreateDocument, useDestroyDocument };
