import type { AxiosResponse } from 'axios';

import type { Group } from '@/api/group';
import type { CCLoan, GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { api } from '@/api/index';

type AasmState = 'inviting' | 'draft' | 'application_in_review' | 'application_rejected' | 'in_kyc' |
  'kyc_in_review' | 'contracts_signature_pending' | 'contracts_signed' | 'loan_approved';

interface BaseGroupLoanApplication {
  id: number;
  groupId: number;
  aasmState: AasmState;
  contractsSignedAt: string | null;
  createdAt: string;
  updatedAt: string;
  submittedAt: string | null;
  repaymentPeriods: number;
  cycle: number;
}

interface GroupLoanApplication extends BaseGroupLoanApplication {
  totalRequestedAmount: number;
  delinquentFees: number;
  group: Group;
  memberLoanApplications: GroupMemberLoanApplication[];
  inactiveMemberLoanApplications: GroupMemberLoanApplication[];
}

interface ConsolidatedLoan {
  groupMemberLoanApplicationIds: number[];
  loans: CCLoan[];
  mostRecentLoanStartDate: string;
  mostRecentDelinquentBalanceDate: string | null;
  mostRecentDelinquentBalanceAmount: number;
  mostRecentDelinquentPaymentsCount: number;
  worstDelinquencyStrike: number;
}

type GroupLoanApplicationResponse = AxiosResponse<{ groupLoanApplication: BaseGroupLoanApplication }>;

const basePath = '/api/internal/group_loan_applications';

function get(id: number) {
  return api.get<{ groupLoanApplication: GroupLoanApplication }>(`${basePath}/${id}`);
}

function update(id: number, groupLoanApplication: Partial<BaseGroupLoanApplication>) {
  return api.put<{ groupLoanApplication: GroupLoanApplication }>(`${basePath}/${id}`, { groupLoanApplication });
}

export type {
  AasmState,
  BaseGroupLoanApplication,
  GroupLoanApplication,
  GroupLoanApplicationResponse,
  ConsolidatedLoan,
};

export { get, update };
