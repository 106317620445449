<script setup lang="ts">
import { ArrowUturnRightIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { useLocalStorage } from '@vueuse/core';
import { formatDistanceToNow } from 'date-fns';
import { ref, computed } from 'vue';

import type { GroupMemberLoanApplication, Section } from '@/api/groupMemberLoanApplication';
import BaseHeaderTable from '@/components/base-header-table.vue';
import BaseTabs from '@/components/base-tabs.vue';
import Comments from '@/components/comments.vue';
import ConsolidatedCreditReportLoansTable from '@/components/reviews/consolidated-credit-report-loans-table.vue';
import ConsolidatedCreditReport from '@/components/reviews/consolidated-credit-report.vue';
import { getGroupLoansFromConsolidatedCreditReport } from '@/utils/creditReport';
import { groupMemberLoanApplicationBadgeColor } from '@/utils/states';

type ImageUrlKeys = 'idFrontUrl' | 'idBackUrl' | 'idSelfieUrl' | 'addressProofUrl';

const props = defineProps<{
  groupMemberLoanApplication: GroupMemberLoanApplication
}>();

const groupMember = computed(() => props.groupMemberLoanApplication.groupMember);
const user = computed(() => groupMember.value.user);

const selectedTab = ref(0);

const SECTIONS_TO_REMOVE = ['address'];
const FIELDS_TO_REMOVE = ['firstName', 'paternalSurname', 'maternalSurname'];

const loanApplicationSchema = computed(
  () => props.groupMemberLoanApplication.form.data,
);

const loanApplicationFilteredOrderedSections = computed(
  () => Object.entries(loanApplicationSchema.value.sections || [])
    .filter(([key, _]) => !SECTIONS_TO_REMOVE.includes(key))
    .map(([_, section]) => section)
    .sort((a, b) => a.order - b.order),
);

function filteredSortedFields(fields: Section['fields']) {
  return Object.entries(fields)
    .filter(([_, field]) => Boolean(field.value))
    .filter(([key, _]) => !FIELDS_TO_REMOVE.includes(key))
    .map(([_, value]) => value)
    .sort((a, b) => a.order - b.order);
}

const rotations = useLocalStorage<{ [key in ImageUrlKeys]: number }>(
  `rotations-${props.groupMemberLoanApplication.id}`,
  {
    idFrontUrl: 0,
    idBackUrl: 0,
    idSelfieUrl: 0,
    addressProofUrl: 0,
  },
);

function openImageInNewTab(url: string, rotation: number) {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.write(`
      <html>
        <head>
          <title>Image</title>
          <style>
            body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }
            img { transform: rotate(${rotation}deg); }
          </style>
        </head>
        <body>
          <img src="${url}" />
        </body>
      </html>
    `);
    newWindow.document.close();
  }
}

function rotateImage(imageKey: ImageUrlKeys) {
  // eslint-disable-next-line no-magic-numbers
  rotations.value[imageKey] = (rotations.value[imageKey] + 90) % 360;
}
</script>

<template>
  <base-tabs
    :tab-titles="groupMemberLoanApplication.consolidatedCreditReport ? ['Solicitud', 'Reporte CC'] : ['Solicitud']"
    :selected-tab-index="selectedTab"
  >
    <template #tab-0>
      <div class="flex flex-col gap-y-10 p-2">
        <div class="flex flex-row">
          <div class="flex flex-col gap-y-3">
            <base-badge
              v-if="groupMember.active"
              :color="groupMemberLoanApplicationBadgeColor(groupMemberLoanApplication.aasmState)"
              :label="$t(`groupMemberLoanApplication.aasmState.${groupMemberLoanApplication.aasmState}`)"
            />
            <base-badge
              v-else
              color="red"
              label="Inactivo"
            />
            <div
              v-if="groupMemberLoanApplication.preApproved"
              class="flex flex-row gap-x-12"
            >
              <div
                v-if="groupMemberLoanApplication.acceptedAmount"
                class="flex flex-col gap-y-1"
              >
                <span class="text-sm font-medium text-gray-700">
                  💰 Monto otorgado
                </span>
                <span class="text-lg font-medium">
                  {{ $filters.toCurrency(groupMemberLoanApplication.acceptedAmount) }}
                </span>
              </div>
              <div
                v-if="groupMemberLoanApplication.interestRate"
                class="flex flex-col gap-y-1"
              >
                <span class="text-sm font-medium text-gray-700">
                  📌 Tasa de interés
                </span>
                <span class="text-lg font-medium">
                  {{ (groupMemberLoanApplication.interestRate * 100).toFixed(2) }}%
                </span>
              </div>
            </div>
          </div>
          <div class="ml-auto flex flex-col self-start">
            <base-button
              :href="`https://wa.me/${user.phoneNumber}`"
              text="Abrir chat de WhatsApp"
              variant="white"
            />
          </div>
        </div>
        <div
          v-if="groupMemberLoanApplication.applicationSubmittedAt || groupMemberLoanApplication.kycSubmittedAt"
          class="flex flex-col gap-y-3"
        >
          <div
            v-if="groupMemberLoanApplication.applicationSubmittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
            <span class="text-sm text-gray-700">
              Solicitud enviada
              {{
                formatDistanceToNow(
                  new Date(groupMemberLoanApplication.applicationSubmittedAt
                  ), { addSuffix: true }
                )
              }}
            </span>
          </div>
          <div
            v-if="groupMemberLoanApplication.kycSubmittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
            <span class="text-sm text-gray-700">
              KYC enviado
              {{ formatDistanceToNow(new Date(groupMemberLoanApplication.kycSubmittedAt), { addSuffix: true }) }}
            </span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-12">
          <ul
            v-if="groupMember.invitation"
            class="flex flex-col gap-y-1"
          >
            <span class="mb-3 text-sm font-semibold text-primary-600">
              Invitación de {{ user.firstName }}
            </span>
            <li
              v-for="item in [
                { label: 'Relación con la presidenta', value: groupMember.invitation.relationship },
                { label: 'Se conocen hace', value: groupMember.invitation.humanYearsKnown },
              ]"
              :key="item.label"
              class="text-xs"
            >
              <b>{{ item.label }}</b>: {{ item.value }}
            </li>
          </ul>
          <ul class="flex flex-col gap-y-1">
            <span class="mb-3 text-sm font-semibold text-primary-600">
              Información personal
            </span>
            <li
              v-for="item in [
                { label: 'ID', value: groupMemberLoanApplication.publicId },
                { label: 'Nombre', value: user.fullName },
                { label: 'Correo electrónico', value: user.email },
                { label: 'Teléfono', value: user.phoneNumber },
                { label: 'Estado', value: user.state },
                { label: 'Municipio', value: user.municipality },
                { label: 'Colonia', value: user.colony },
                { label: 'Calle', value: user.street },
                { label: 'Número exterior', value: user.externalNumber },
                { label: 'Número interior', value: user.internalNumber },
                { label: 'Código postal', value: user.postalCode },
                { label: 'Rol', value: groupMember.humanRole },
              ]"
              :key="item.label"
              class="text-xs"
            >
              <b>{{ item.label }}</b>: {{ item.value }}
            </li>
          </ul>
          <ul
            v-for="section in loanApplicationFilteredOrderedSections"
            :key="section.order"
            class="flex flex-col gap-y-1"
          >
            <span class="mb-3 text-sm font-semibold text-primary-600">
              {{ section.title }}
            </span>
            <template
              v-if="Object.values(section.fields).some(field => Boolean(field.value))"
            >
              <li
                v-for="item in filteredSortedFields(section.fields).map((field) => ({
                  label: field.label,
                  value: field.value,
                }))"
                :key="item.label"
                class="text-xs"
              >
                <b>{{ item.label }}</b>: {{ item.value }}
              </li>
            </template>
            <span
              v-else
              class="text-xs text-gray-700"
            >
              No hay respuestas aún
            </span>
          </ul>
        </div>
        <span class="mt-6 font-semibold">
          Creditos grupales
        </span>
        <consolidated-credit-report-loans-table
          :loans="getGroupLoansFromConsolidatedCreditReport(
            groupMemberLoanApplication.consolidatedCreditReport
          )"
        />
        <base-header-table
          v-if="groupMemberLoanApplication.kycSubmittedAt"
          size="sm"
          title="Referencias"
          :headers="['Nombre', 'Teléfono', 'Relación']"
          :rows="groupMemberLoanApplication.references.map((reference) => [
            reference.name,
            reference.phoneNumber,
            reference.relationship,
          ])"
        />
        <div class="flex flex-col gap-y-6 rounded-lg bg-gray-50 p-4">
          <h2 class="font-semibold">
            Formulario de KYC
          </h2>
          <div
            v-if="groupMemberLoanApplication.kycSubmittedAt"
            class="flex flex-col gap-y-10"
          >
            <div class="flex flex-row flex-wrap gap-4 self-start">
              <div class="group relative">
                <img
                  :src="groupMemberLoanApplication.idFrontUrl as string"
                  :style="{ transform: `rotate(${rotations.idFrontUrl}deg)` }"
                  class="h-44 w-44 cursor-pointer rounded"
                  @click="openImageInNewTab(groupMemberLoanApplication.idFrontUrl as string, rotations.idFrontUrl)"
                >
                <button
                  class="absolute right-0 top-0 mr-1 mt-1 rounded-full border border-gray-300 bg-white p-1 opacity-0 shadow transition-opacity duration-200 group-hover:opacity-100"
                  @click="rotateImage('idFrontUrl')"
                >
                  <arrow-uturn-right-icon class="h-3 w-3 text-black" />
                </button>
              </div>
              <div class="group relative">
                <img
                  :src="groupMemberLoanApplication.idBackUrl as string"
                  :style="{ transform: `rotate(${rotations.idBackUrl}deg)` }"
                  class="h-44 w-44 cursor-pointer rounded"
                  @click="openImageInNewTab(groupMemberLoanApplication.idBackUrl as string, rotations.idBackUrl)"
                >
                <button
                  class="absolute right-0 top-0 mr-1 mt-1 rounded-full border border-gray-300 bg-white p-1 opacity-0 shadow transition-opacity duration-200 group-hover:opacity-100"
                  @click="rotateImage('idBackUrl')"
                >
                  <arrow-uturn-right-icon class="h-3 w-3 text-black" />
                </button>
              </div>
              <div class="group relative">
                <img
                  :src="groupMemberLoanApplication.idSelfieUrl as string"
                  :style="{ transform: `rotate(${rotations.idSelfieUrl}deg)` }"
                  class="h-44 w-44 cursor-pointer rounded"
                  @click="openImageInNewTab(groupMemberLoanApplication.idSelfieUrl as string, rotations.idSelfieUrl)"
                >
                <button
                  class="absolute right-0 top-0 mr-1 mt-1 rounded-full border border-gray-300 bg-white p-1 opacity-0 shadow transition-opacity duration-200 group-hover:opacity-100"
                  @click="rotateImage('idSelfieUrl')"
                >
                  <arrow-uturn-right-icon class="h-3 w-3 text-black" />
                </button>
              </div>
              <div class="group relative">
                <img
                  :src="groupMemberLoanApplication.addressProofUrl as string"
                  :style="{ transform: `rotate(${rotations.addressProofUrl}deg)` }"
                  class="h-44 w-44 cursor-pointer rounded"
                  @click="openImageInNewTab(
                    groupMemberLoanApplication.addressProofUrl as string,
                    rotations.addressProofUrl
                  )"
                >
                <button
                  class="absolute right-2 top-2 flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-gray-300 bg-white p-1 opacity-0 shadow transition-opacity duration-200 group-hover:opacity-100"
                  @click="rotateImage('addressProofUrl')"
                >
                  <arrow-uturn-right-icon class="h-3 w-3 text-black" />
                </button>
              </div>
            </div>
          </div>
          <span v-else>
            Aun no hay KYC
          </span>
        </div>
        <comments
          name="comment[body]"
          resource-type="GroupMemberLoanApplication"
          :resource-id="groupMemberLoanApplication.id"
        />
      </div>
    </template>
    <template
      v-if="groupMemberLoanApplication.consolidatedCreditReport"
      #tab-1
    >
      <consolidated-credit-report
        :group-member-loan-application="groupMemberLoanApplication"
      />
    </template>
  </base-tabs>
</template>
