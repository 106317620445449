<script setup lang="ts">
import { computed, inject, ref, toRef, type Ref } from 'vue';

import type { ResourceComment } from '@/api/comment';
import type { IUser } from '@/api/currentUser';
import { useFetchDocuments } from '@/api/document/queries';
import { formatRelativeDate } from '@/utils/filters';
import { currentUserKey } from '@/utils/keys';

interface Props {
  comment: ResourceComment;
}

const props = defineProps<Props>();

const currentUser: Ref<IUser> | undefined = inject(currentUserKey);
const isAuthor = computed(() => !!currentUser && currentUser.value.id === props.comment.author.id);

const documentsQ = useFetchDocuments({
  documentableType: ref('Comment'),
  documentableId: toRef(props.comment, 'id'),
});

function getDocumentUrlFromId(id: number) {
  const document = documentsQ.data?.find(doc => doc.id === id);

  return document?.fileUrl;
}

// eslint-disable-next-line max-len, vue/max-len
const ATTACHMENT_REGEX = /<action-text-attachment[^>]*content-type="([^"]*)"[^>]*url="([^"]*)"[^>]*filename="([^"]*)"[^>]*><\/action-text-attachment>/g;

const formattedBody = computed(() => (
  props.comment.body.replace(
    ATTACHMENT_REGEX,
    (_match, contentType: string, url: string, filename: string) => {
      const documentUrl = getDocumentUrlFromId(Number(url));

      if (contentType.startsWith('image')) {
        return `<a href="${documentUrl}" target="_blank" rel="noopener noreferrer">
                  <img
                    src="${documentUrl}"
                    alt="${filename}"
                    class="max-w-24 max-h-24 border border-primary-600 p-2 rounded"
                  />
                </a>`;
      }

      return `<a class="text-primary-600" href="${documentUrl}" target="_blank" rel="noopener noreferrer">
                ${filename}
              </a>`;
    },
  )
));
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col gap-y-2 rounded-lg border bg-white px-4 py-3 shadow">
    <div class="mb-1 flex flex-row items-center gap-x-2 text-xs font-light text-gray-700">
      <span
        v-if="isAuthor"
        class="italic"
      >
        Tú
      </span>
      <span v-else>
        {{ comment.author.firstName }}
      </span>
      <span>-</span>
      <span>
        {{ formatRelativeDate(props.comment.createdAt) }}
      </span>
    </div>
    <div
      class="overflow-scroll text-sm text-gray-700"
      v-html="formattedBody"
    />
  </div>
</template>
