import type { GroupMember } from '@/api/groupMember';
import { api } from '@/api/index';
import type { Reference } from '@/api/reference';

type AasmStates = 'draft'
| 'submitted'
| 'rejected'
| 'in_kyc'
| 'kyc_submitted'
| 'contract_signature_pending'
| 'contract_signed';

interface Field {
  value?: any;
  _default?: string | number | boolean;
  label?: string;
  order?: number;
  description?: string;
}

interface Section {
  title: string;
  order: number;
  fields: { [fieldName: string]: Field };
}

interface Form {
  id: number;
  resourceId: number;
  resourceType: string;
  data: {
    formSchema: string,
    sections: { [sectionName: string]: Section }
  };
  schemaVersion: string;
  createdAt: string;
  updatedAt: string;
}

type CCPaymentFrequency = 'B' | 'M' | 'Q' | 'A' | 'D' | 'T' | 'S' | 'E' | 'C' | 'U' | 'R';
type CCLoanType = 'F' | 'H' | 'L' | 'R' | 'Q' | 'A' | 'E' | 'P';
type CCResponsibilityType = 'I' | 'M' | 'O' | 'A' | 'T'

interface CCLoan {
  cAN: string | null;
  claveOtorgante: string | null;
  clavePrevencion: string | null;
  claveUnidadMonetaria: 'MX';
  creditoMaximo: number;
  cuentaActual: string | null;
  fechaActualizacion: string;
  fechaAntiguaHistoricoPagos: string | null;
  fechaAperturaCuenta: string;
  fechaCierreCuenta: string;
  fechaPeorAtraso: string | null;
  fechaRecienteHistoricoPagos: string | null;
  fechaReporte: string;
  fechaUltimaCompra: string;
  fechaUltimoPago: string;
  frecuenciaPagos: CCPaymentFrequency;
  garantia: string | null;
  historicoPagos: string;
  limiteCredito: number;
  montoPagar: number;
  montoUltimoPago: string | null;
  nombreOtorgante: string;
  numeroPagos: number;
  numeroPagosVencidos: number;
  pagoActual: string;
  peorAtraso: number;
  registroImpugnado: number;
  saldoActual: number;
  saldoVencido: number;
  saldoVencidoPeorAtraso: number;
  tipoCredito: CCLoanType;
  tipoCuenta: string;
  tipoResponsabilidad: CCResponsibilityType;
  totalPagosReportados: number;
  ultimaFechaSaldoCero: string | null;
  valorActivoValuacion: number;
}

interface CCLoanInquiry {
  fechaConsulta: string;
  nombreOtorgante: string;
  tipoCredito: string;
  importeCredito: number;
  claveUnidadMonetaria: string;
}

interface CCLoanAddress {
  direccion: string;
  coloniaPoblacion: string;
  delegacionMunicipio: string;
  ciudad: string;
  estado: string;
  cP: string;
  numeroTelefono: string;
  fechaRegistroDomicilio: string;
}

interface ConsolidatedCreditReport {
  creditos: CCLoan[];
  consultas: CCLoanInquiry[];
  persona: {
    nombres: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    fechaNacimiento: string;
    rFC: string;
  },
  domicilios: CCLoanAddress[];
}

interface RelationShipWithNewMember {
  id: number;
  relationship: string;
  approved: boolean;
}

interface BaseGroupMemberLoanApplication {
  id: number;
  publicId: number;
  groupMemberId: number;
  groupLoanApplicationId: number;
  amount: number | null;
  acceptedAmount: number | null;
  interestRate: number;
  aasmState: AasmStates;
  operationsAffirmation: boolean;
  creditConsultationConsent: boolean;
  bankAccountNumber: string | null;
  previousCycleBankAccountNumber: string | null;
  unsignedContractGenerateJobStatus: string | null;
  unsignedContractUrl: string | null;
  signedContractUrl: string | null;
  signatureUrl: string | null;
  contractSigned: boolean;
  selectedPlatform: string | null;
  consolidated: boolean;
  meetsLoanConditions: boolean;
  consolidatedCreditReport: ConsolidatedCreditReport;
  creditBureauReportUrl: string | null;
  idFrontUrl: string | null;
  idBackUrl: string | null;
  idSelfieUrl: string | null;
  addressProofUrl: string | null;
  applicationSubmittedAt: string | null;
  kycSubmittedAt: string | null;
  preApproved: boolean;
  cycle: number;
  relationshipWithNewMembers: RelationShipWithNewMember[];
  totalRepaymentAmount: number | null;
  repaymentAmount: number | null;
  costPerThousand: number | null;
  income: number | null;
  expenses: number | null;
  loanPaymentAbility: number | null;
  internalLoanPaymentAbility: number | null;
}

interface GroupMemberLoanApplication extends BaseGroupMemberLoanApplication {
  groupMember: GroupMember;
  references: Reference[];
  form: Form;
}

function submit(body: object) {
  const path = '/api/internal/group_member_loan_application/submission';

  return api.post<{ form: Form }>(path, body);
}

function createAuthorizationCode() {
  const path = '/api/internal/group_member_loan_application/authorization_code';

  return api.post<void>(path);
}

function createAuthorizationCodeValidation(code: string) {
  const path = '/api/internal/group_member_loan_application/authorization_code_validation';

  return api.post<void>(path, { code });
}

function updateCurpAndAddress(formId: number, body: object) {
  const path = `/api/internal/group_member_loan_applications/curp_and_address_forms/${formId}`;

  return api.put<void>(path, body);
}

export type {
  Field,
  Form,
  Section,
  CCLoan,
  ConsolidatedCreditReport,
  BaseGroupMemberLoanApplication,
  GroupMemberLoanApplication,
};

export {
  submit,
  createAuthorizationCode,
  createAuthorizationCodeValidation,
  updateCurpAndAddress,
};
