import { api } from '@/api';

interface BaseUser {
  id: number;
  firstName: string | null;
  paternalSurname: string | null;
  maternalSurname: string | null;
  identificationNumber: string | null;
  email: string | null;
  fullName: string | null;
  phoneNumber: string;
  birthDate: string | null;
  sex: 'male' | 'female' | null;
  country: string | null;
  city: string | null;
  state: string | null;
  street: string | null;
  externalNumber: string | null;
  internalNumber: string | null;
  colony: string | null;
  municipality: string | null;
  postalCode: string | null;
}

type User = BaseUser

export type {
  BaseUser,
  User,
};

interface LoginParams {
  phoneNumber: string;
  magicCode: string;
}

function login(body: LoginParams) {
  const path = '/api/internal/sessions';

  return api.post<{ user: User, redirectPath: string }>(path, body);
}

function createMagicCode(phoneNumber: string) {
  const path = '/api/internal/users/magic_codes';

  return api.post<void>(path, { phoneNumber });
}

export { login, createMagicCode };
