import { api } from '../index';

interface IUser {
  id: number;
  firstName: string;
  paternalSurname: string;
  maternalSurname: string;
  fullName: string;
  phoneNumber: string;
  birthDate: string | null;
  sex: 'male' | 'female';
  country: string;
  city: string;
  state: string;
  avatarUrl: string | null;
  removeAvatar: boolean;
}

function update(user: Partial<IUser & { removeAvatar: boolean }>) {
  const path = '/api/internal/current_user';

  return api<{ user: IUser }>({
    method: 'patch',
    url: path,
    data: { user },
  }).then((response) => response.data.user);
}

function updateAvatar(image: File) {
  const path = '/api/internal/current_user';
  const formData = new FormData();
  formData.append('user[avatar]', image);

  return api<{ user: IUser }>({
    method: 'patch',
    url: path,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  }).then((response) => response.data.user);
}

export type { IUser };

export { update, updateAvatar };
