<script setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { inject } from 'vue';
import { object, string } from 'yup';

import { currentUserKey } from '@/utils/keys';

defineProps({
  subordinate: { type: Object, default: null },
  subordinates: { type: Array, default: null },
});

const currentUser = inject(currentUserKey);

const validationSchema = object({
  'phone_number': string().mobilePhone(['es-MX', 'es-CL']).required('Número de teléfono es obligatorio'),
});
</script>

<template>
  <div class="flex w-full max-w-md flex-col">
    <div v-if="subordinate">
      <base-back-button
        :href="`/promoters/${currentUser.id}/home`"
      />
      <span class="font-medium">
        Estas viendo el perfil de {{ subordinate.firstName }}
      </span>
    </div>
    <rails-flash-alerts />
    <v-form-native-wrapper
      v-if="!subordinate"
      class="flex flex-col rounded-lg bg-gray-100 p-2"
      action="/promoters/president_invites"
      method="POST"
      :validation-schema="validationSchema"
    >
      <h2 class="text-lg font-medium">
        Invitar a una presidenta a crear un grupo
      </h2>
      <base-phone-input
        label="Número de la presidenta"
        description="Ingresa el número de la presidenta y le llegará
                    un mensaje a Whatsapp con la información para crear su grupo"
        name="phone_number"
        class="mt-8"
      />
      <base-button
        type="submit"
        text="Enviar invitación"
        class="mt-4"
      />
    </v-form-native-wrapper>

    <a
      class="mt-10 flex flex-row items-center justify-between rounded-lg bg-gray-200 p-2"
      :href="`/promoters/${subordinate ? subordinate.id : currentUser.id}/group_loan_applications`"
    >
      <div class="flex flex-row items-center gap-x-2">
        <span class="text-xl">
          📱
        </span>
        <h2 class="text-lg">
          Solicitudes en proceso {{ subordinate ? 'de ' + subordinate.firstName : '' }}
        </h2>
      </div>
      <ChevronRightIcon
        class="ml-auto h-5 w-5"
      />
    </a>
    <a
      class="mt-4 flex flex-row items-center justify-between rounded-lg bg-gray-200 p-2"
      :href="`/promoters/${subordinate ? subordinate.id : currentUser.id}/group_loans`"
    >
      <div class="flex flex-row items-center gap-x-2">
        <span class="text-xl">
          💸
        </span>
        <h2 class="text-lg">
          Grupos activos {{ subordinate ? 'de ' + subordinate.firstName : '' }}
        </h2>
      </div>
      <ChevronRightIcon
        class="ml-auto h-5 w-5"
      />
    </a>
    <div
      v-if="subordinates && !subordinate"
      class="mt-10"
    >
      <span class="font-medium">
        Promotores a tu cargo
      </span>
      <ul class="mt-4 flex list-inside list-disc flex-col">
        <li
          v-for="sub in subordinates"
          :key="sub.id"
        >
          <a
            :href="`/promoters/${sub.id}/home`"
            class="text-blue-500 underline"
          >
            Ver perfil de {{ sub.firstName }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
