/* eslint-disable max-statements */
import { useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import { reactive, unref, watchEffect } from 'vue';

export default function useReactiveQuery<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData,
  TQueryKey = unknown
>(options: UseQueryOptions<TData, TError, TQueryFnData, TQueryKey>) {
  const queryResult = useQuery(options);

  const reactiveQueryResult = reactive({
    data: unref(queryResult.data),
    dataUpdatedAt: unref(queryResult.dataUpdatedAt),
    error: unref(queryResult.error),
    errorUpdatedAt: unref(queryResult.errorUpdatedAt),
    failureCount: unref(queryResult.failureCount),
    failureReason: unref(queryResult.failureReason),
    fetchStatus: unref(queryResult.fetchStatus),
    isError: unref(queryResult.isError),
    isFetched: unref(queryResult.isFetched),
    isFetchedAfterMount: unref(queryResult.isFetchedAfterMount),
    isFetching: unref(queryResult.isFetching),
    isLoading: unref(queryResult.isLoading),
    isLoadingError: unref(queryResult.isLoadingError),
    isPaused: unref(queryResult.isPaused),
    isPending: unref(queryResult.isPending),
    isPlaceholderData: unref(queryResult.isPlaceholderData),
    isRefetchError: unref(queryResult.isRefetchError),
    isRefetching: unref(queryResult.isRefetching),
    isStale: unref(queryResult.isStale),
    isSuccess: unref(queryResult.isSuccess),
    refetch: unref(queryResult.refetch),
    status: unref(queryResult.status),
  });

  watchEffect(
    () => {
      if (queryResult) {
        reactiveQueryResult.data = unref(queryResult.data);
        reactiveQueryResult.dataUpdatedAt = unref(queryResult.dataUpdatedAt);
        reactiveQueryResult.error = unref(queryResult.error);
        reactiveQueryResult.errorUpdatedAt = unref(queryResult.errorUpdatedAt);
        reactiveQueryResult.failureCount = unref(queryResult.failureCount);
        reactiveQueryResult.failureReason = unref(queryResult.failureReason);
        reactiveQueryResult.fetchStatus = unref(queryResult.fetchStatus);
        reactiveQueryResult.isError = unref(queryResult.isError);
        reactiveQueryResult.isFetched = unref(queryResult.isFetched);
        reactiveQueryResult.isFetchedAfterMount = unref(queryResult.isFetchedAfterMount);
        reactiveQueryResult.isFetching = unref(queryResult.isFetching);
        reactiveQueryResult.isLoading = unref(queryResult.isLoading);
        reactiveQueryResult.isLoadingError = unref(queryResult.isLoadingError);
        reactiveQueryResult.isPaused = unref(queryResult.isPaused);
        reactiveQueryResult.isPending = unref(queryResult.isPending);
        reactiveQueryResult.isPlaceholderData = unref(queryResult.isPlaceholderData);
        reactiveQueryResult.isRefetchError = unref(queryResult.isRefetchError);
        reactiveQueryResult.isRefetching = unref(queryResult.isRefetching);
        reactiveQueryResult.isStale = unref(queryResult.isStale);
        reactiveQueryResult.isSuccess = unref(queryResult.isSuccess);
        reactiveQueryResult.refetch = unref(queryResult.refetch);
        reactiveQueryResult.status = unref(queryResult.status);
      }
    },
  );

  return reactiveQueryResult;
}

