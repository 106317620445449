import { reactive } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import useUIStore from '@/stores/ui';
import phoneNumberValidationApi from '@/api/phoneNumberValidation';

export default function usePhoneNumberValidation() {
  const uiStore = useUIStore();

  const state = reactive({
    validated: null,
    loading: false,
    number: null,
  });

  const { mutate: validate } = useMutation({
    mutationFn: (number) => phoneNumberValidationApi.create(number).then(response => response.data),
    onMutate: (number) => {
      state.loading = true;
      state.validated = null;
      state.number = number;
    },
    onError: (err) => {
      state.loading = false;
      uiStore.toast({
        message: err.response?.data?.detail || 'Hubo un error al validar el número de teléfono',
        type: 'error',
        position: 'top',
      });
      Sentry.captureException(err.value);
    },
    onSuccess: ({ phoneNumber }) => {
      state.validated = phoneNumber.validated;
      state.loading = false;
    },
  });

  return {
    state,
    validate,
  };
}
