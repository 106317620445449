<script setup>
import { inject } from 'vue';

import { flashMessagesKey } from '@/utils/keys';

import BaseFlashAlert from './base-flash-alert.vue';

defineProps({
  flashAlertClasses: { type: String, default: 'mb-2 last:mb-8' },
});

const flashMessages = inject(flashMessagesKey);
</script>

<template>
  <div class="flex w-full flex-col">
    <base-flash-alert
      v-for="flashMessage in flashMessages"
      :key="flashMessage.id"
      :flash="[flashMessage.type, flashMessage.text]"
      :class="flashAlertClasses"
    />
  </div>
</template>
