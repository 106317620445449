import { ref, type MaybeRef } from 'vue';

const DOCUMENTS_BASE_KEY = ['documents'];

function documentKey(id: MaybeRef<number>) {
  return [...DOCUMENTS_BASE_KEY, ref(id)];
}

function documentsKey(documentableId: MaybeRef<number>, documentableType: MaybeRef<string>) {
  return [...DOCUMENTS_BASE_KEY, { documentableId: ref(documentableId), documentableType: ref(documentableType) }];
}

export { DOCUMENTS_BASE_KEY, documentKey, documentsKey };
