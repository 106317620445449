function cloneAndReplaceCanvases(content: HTMLElement): HTMLElement {
  const clonedContent = content.cloneNode(true) as HTMLElement;
  const clonedCanvases = clonedContent.querySelectorAll('canvas');
  const originalCanvases = content.querySelectorAll('canvas');

  clonedCanvases.forEach((canvas, index) => {
    const originalCanvas = originalCanvases[index] as HTMLCanvasElement;
    if (originalCanvas) {
      const dataURL = originalCanvas.toDataURL('image/png');
      const img = clonedContent.ownerDocument.createElement('img');
      img.src = dataURL;
      img.alt = 'Chart Image';
      img.style.maxWidth = '100%';
      img.style.height = 'auto';
      canvas.parentNode?.replaceChild(img, canvas);
    }
  });

  return clonedContent;
}

function createHiddenIframe(): HTMLIFrameElement {
  const printFrame = document.createElement('iframe');
  Object.assign(printFrame.style, {
    position: 'fixed',
    right: '0',
    bottom: '0',
    width: '0',
    height: '0',
    border: '0',
    visibility: 'hidden',
  });
  document.body.appendChild(printFrame);

  return printFrame;
}

function collectExternalStyles(): string {
  const styles = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'));

  return styles
    .map((style) => {
      if (style.tagName.toLowerCase() === 'link') {
        const href = (style as HTMLLinkElement).href;
        const absoluteHref = new URL(href, document.baseURI).toString();

        return `<link rel="stylesheet" href="${absoluteHref}">`;
      }

      return style.outerHTML;
    }).join('\n');
}

function generateHeadContent(): string {
  const baseStyles = `
    <title>Grupalia</title>
    <style>
      @page {
        margin: 0;
      }
      body {
        padding-left: 10px;
        padding-right: 10px;
      }
    </style>
  `;
  const externalStyles = collectExternalStyles();

  return `${baseStyles}\n${externalStyles}`;
}

function generateBodyContent(clonedContent: HTMLElement): string {
  return clonedContent.innerHTML;
}

function generateFullHtml(clonedContent: HTMLElement): string {
  const headContent = generateHeadContent();
  const bodyContent = generateBodyContent(clonedContent);

  return `
    <!DOCTYPE html>
    <html>
      <head>
        ${headContent}
      </head>
      <body>
        ${bodyContent}
      </body>
    </html>
  `;
}

function writeContentToIframe(
  printFrame: HTMLIFrameElement,
  clonedContent: HTMLElement,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const frameDoc = printFrame.contentWindow?.document;
    if (frameDoc) {
      frameDoc.open();

      const htmlContent = generateFullHtml(clonedContent);
      frameDoc.write(htmlContent);
      frameDoc.close();

      printFrame.onload = () => {
        resolve();
      };
    } else {
      reject(new Error('Unable to access the iframe document.'));
    }
  });
}

const REMOVE_CHILDREN_TIMEOUT = 1000;
function handlePrint(printFrame: HTMLIFrameElement) {
  setTimeout(() => {
    const iframeWindow = printFrame.contentWindow;
    if (iframeWindow) {
      iframeWindow.focus();
      iframeWindow.print();
      setTimeout(() => {
        document.body.removeChild(printFrame);
      }, REMOVE_CHILDREN_TIMEOUT);
    }
  }, REMOVE_CHILDREN_TIMEOUT);
}

export default function htmlToPdf(content: HTMLElement) {
  const clonedContent = cloneAndReplaceCanvases(content);
  const printFrame = createHiddenIframe();

  writeContentToIframe(printFrame, clonedContent).then(() => handlePrint(printFrame));
}

