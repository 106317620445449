import { type MutationOptions, useQueryClient } from '@tanstack/vue-query';
import { toRef, unref, type MaybeRef } from 'vue';

import { type ApiError } from '@/api';
import { type GroupLoanApplication } from '@/api/groupLoanApplication';
import {
  type UpdatePayload,
  type PreApprovePayload,
  generateUnsignedContracts,
  update,
  preApprove,
} from '@/api/groupLoanApplication/reviews';
import { groupLoanApplicationKey } from '@/api/queryKeys/index';
import useReactiveMutation from '@/composables/useReactiveMutation';

type GenerateUnsignedContractsComposableArgs = {
  groupLoanApplicationId: MaybeRef<number>
} & MutationOptions<GroupLoanApplication, ApiError, MaybeRef<number>>

function useGenerateUnsignedContracts({
  groupLoanApplicationId, ...options
}: GenerateUnsignedContractsComposableArgs) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: () => (
      generateUnsignedContracts(unref(groupLoanApplicationId)).then((response) => response.data.groupLoanApplication)
    ),
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(toRef(variables)) });
    },
  });
}

type updateGroupLoanApplicationComposableArgs<TPayload> = {
  id: MaybeRef<number>
} & MutationOptions<GroupLoanApplication, ApiError, TPayload>

function useUpdateGroupLoanApplication<TPayload = UpdatePayload>({
  id, ...options
} : updateGroupLoanApplicationComposableArgs<TPayload>) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: (groupLoanApplication) => (
      update(unref(id), groupLoanApplication)
        .then((response) => response.data.groupLoanApplication)
    ),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(id) });
    },
  });
}

type preApproveGroupLoanApplicationComposableArgs = {
  id: MaybeRef<number>
} & MutationOptions<void, ApiError, PreApprovePayload>

function usePreApproveGroupLoanApplication({
  id, ...options
}: preApproveGroupLoanApplicationComposableArgs) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: (body) => preApprove(unref(id), body).then((response) => response.data),
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(id) });
    },
  });
}

export {
  useGenerateUnsignedContracts,
  useUpdateGroupLoanApplication,
  usePreApproveGroupLoanApplication,
};
