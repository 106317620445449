import { api } from './index';

const path = '/api/internal/copomex';

function getCpInfo(postalCode: string) {
  return api({
    method: 'get',
    url: `${path}/info_cp/${postalCode}`,
  });
}

export { getCpInfo };
