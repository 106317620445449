<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query';
import { ref, reactive, computed, onMounted } from 'vue';
import { object, string } from 'yup';

import type { ApiError } from '@/api';
import * as userApi from '@/api/user';
import { useCreateMagicCodeMutation } from '@/api/user/mutations';
import BaseAlert from '@/components/base-alert.vue';

const props = defineProps({
  phoneNumber: { type: String, default: '' },
});

const STATES = Object.freeze({
  login: 'LOGIN',
  magicCode: 'MAGIC_CODE',
});

const currentStep = ref(STATES.login);
const form = reactive({
  phoneNumber: null,
});

const createMagicCodeMutation = useCreateMagicCodeMutation({
  onError: (err) => {
    if (err.response?.status === 404) {
      window.location.href = `/users/sign_up?phone_number=${encodeURIComponent(form.phoneNumber)}`;
    }
  },
});

const validateMagicCodeMutation = useMutation<void, ApiError, { magicCode: string }>({
  mutationFn: userApi.login,
});

const description = computed(() => {
  switch (currentStep.value) {
  case STATES.login:
    return 'Te enviaremos un código a tu <b>Whatsapp</b>. Deberás ingresarlo para entrar a tu cuenta.';
  case STATES.magicCode:
    return false;

  default:
    return false;
  }
});

function handleSubmit(values) {
  if (currentStep.value === STATES.login) {
    form.phoneNumber = values.phoneNumber;
    createMagicCodeMutation.mutate(form.phoneNumber, {
      onSuccess: () => {
        currentStep.value = STATES.magicCode;
      },
    });
  } else if (currentStep.value === STATES.magicCode) {
    validateMagicCodeMutation.mutate({
      phoneNumber: form.phoneNumber,
      magicCode: values.magicCode,
    }, {
      onSuccess: (data) => {
        const redirectPath = data.redirectPath || '/';
        window.location.href = redirectPath;
      },
    });
  }
}

const validationSchema = computed(() => {
  switch (currentStep.value) {
  case STATES.login:
    return object({
      phoneNumber: string().mobilePhone(['es-MX', 'es-CL']).required('Número de teléfono es obligatorio'),
    });
  case STATES.magicCode:
    return object({
      magicCode: string().required('Código es obligatorio'),
    });

  default:
    return object({});
  }
});

onMounted(() => {
  if (props.phoneNumber) {
    window.history.replaceState({}, document.title, window.location.pathname);
    handleSubmit({ phoneNumber: props.phoneNumber });
  }
});
</script>

<template>
  <div class="container flex w-full max-w-lg flex-col items-center sm:mt-20">
    <rails-flash-alerts />
    <v-form
      class="flex w-full flex-col gap-y-8 rounded-xl bg-white px-4 py-6 sm:gap-y-12 sm:px-8"
      :validation-schema="validationSchema"
      @submit="handleSubmit"
    >
      <base-image
        img-name="logo"
        alt="Logo de grupalia"
        class="my-4 h-12 w-auto fill-current text-black"
      />
      <h2 class="text-xl font-bold">
        Iniciar sesión
      </h2>
      <base-alert
        v-if="validateMagicCodeMutation.error"
        type="error"
        :message="validateMagicCodeMutation.error?.response?.data?.detail || 'Código incorrecto'"
      />
      <base-alert
        v-else-if="createMagicCodeMutation.isSuccess"
        type="success"
        message="Te hemos enviado un código a tu Whatsapp."
      />
      <p
        v-if="description"
        class="text-sm"
        v-html="description"
      />
      <div class="flex flex-col gap-y-4">
        <template v-if="currentStep === STATES.login">
          <base-phone-input
            name="phoneNumber"
            label="Número de teléfono"
          />
          <base-button
            text="Iniciar sesión"
            type="submit"
            :loading="createMagicCodeMutation.isPending"
          />
          <span class="self-center text-xs sm:text-sm">
            Aún no tienes una cuenta?
            <a
              class="text-blue-400 hover:opacity-75"
              href="/users/sign_up"
            >
              registrate acá
            </a>
          </span>
        </template>
        <template
          v-else
        >
          <base-short-text-input
            name="magicCode"
            label="Código de ingreso"
          />
          <base-button
            text="Ingresar"
            type="submit"
            :loading="validateMagicCodeMutation.isPending"
          />
        </template>
      </div>
    </v-form>
  </div>
</template>
