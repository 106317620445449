<script setup>
import { useQuery, useMutation } from '@tanstack/vue-query';
import {
  ref,
  watch,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from 'vue';

import * as conversationApi from '@/api/crm/whatsapp/conversation';
import * as userApi from '@/api/crm/whatsapp/user';
import CrmWhatsappContactsList from '@/components/crm/whatsapp/contacts-list.vue';
import CrmWhatsappMessagesBox from '@/components/crm/whatsapp/messages-box.vue';

const props = defineProps({
  conversations: { type: Array, required: true },
});

const queryParams = new URLSearchParams(window.location.search);

const messagesContainerRef = ref(null);
const selectedConversation = ref(props.conversations[0]);
const message = ref(null);

const account = ref(queryParams.get('account') || 'main');
function changeAccount(value) {
  window.location.search = `?account=${value}`;
}

const { data: fullConversation, refetch: refetchMessages } = useQuery({
  queryKey: ['messages', selectedConversation.value.recipientPhone],
  queryFn: () =>
    conversationApi
      .get({
        phoneNumber: selectedConversation.value.recipientPhone,
        account: account.value,
      })
      .then((response) => response.data.whatsappConversation),
});

const conversationUser = computed(() => fullConversation.value?.user);

let refetchInterval;
const REFRESH_INTERVAL = 2000;
onMounted(() => {
  refetchInterval = setInterval(() => {
    refetchMessages();
  }, REFRESH_INTERVAL);
});

onBeforeUnmount(() => {
  clearInterval(refetchInterval);
});

const { mutate: sendReply } = useMutation({
  mutationFn: () =>
    conversationApi.sendReply({
      recipientPhone: selectedConversation.value.recipientPhone,
      text: message.value,
      account: account.value,
    }),
  onSuccess: () => {
    message.value = null;
    refetchMessages();
  },
  enabled: Boolean(selectedConversation),
});

const { mutate: markConversationAsRead } = useMutation({
  mutationFn: () =>
    conversationApi.markAsRead({
      phoneNumber: selectedConversation.value.recipientPhone,
      account: account.value,
    }),
  onSuccess: () => {
    refetchMessages();
  },
});

const shuldScrollToBottom = ref(false);
watch(selectedConversation, () => {
  markConversationAsRead();
  shuldScrollToBottom.value = true;
});

watch(fullConversation, () => {
  if (shuldScrollToBottom.value) {
    shuldScrollToBottom.value = false;
    nextTick(() => {
      messagesContainerRef.value.scrollTop =
        messagesContainerRef.value.scrollHeight;
    });
  }
});

const page = ref(1);
const pageFormRef = ref(null);
function changePage(newPage) {
  if (newPage !== page.value) {
    page.value = newPage;
    nextTick(() => pageFormRef.value?.innerFormRef.submit());
  }
}

const pageParam = queryParams.get('page');
const pageNum = parseInt(pageParam, 10);
if (!isNaN(pageNum) && pageNum >= 1) {
  changePage(pageNum);
} else {
  changePage(1);
}

const { mutate: updateUser } = useMutation({
  mutationFn: (body) => userApi.update(conversationUser.value.id, body),
});
</script>

<template>
  <div class="flex h-full w-full grow flex-row">
    <crm-whatsapp-contacts-list
      :conversations="conversations"
      :selected-conversation="selectedConversation"
      :account="account"
      :page="page"
      @update:page="changePage"
      @update:account="changeAccount"
      @update:selected-conversation="selectedConversation = $event"
    />
    <div
      v-if="fullConversation"
      ref="messagesContainerRef"
      class="flex h-full w-full flex-col overflow-y-scroll bg-primary-50 p-1"
    >
      <div
        class="flex w-full flex-row justify-between gap-x-4 bg-primary-600 p-4"
      >
        <div class="flex flex-col">
          <span class="text-white">
            {{ fullConversation.user?.fullName || "Anonimo" }}
          </span>
          <span class="text-gray-200">
            {{ selectedConversation.recipientPhone }}
          </span>
        </div>
        <div class="flex flex-row items-center gap-x-6">
          <a
            v-if="fullConversation.user"
            :href="`/admin/users/${fullConversation.user.id}`"
            target="_blank"
            class="flex flex-row items-center gap-x-2 text-white"
          >
            <span class="text-sm"> Admin </span>
            <ArrowTopRightOnSquareIcon class="h-6 w-6" />
          </a>
          <a
            :href="`https://wa.me/${selectedConversation.recipientPhone}`"
            target="_blank"
            class="flex flex-row items-center gap-x-2 text-white"
          >
            <span class="text-sm"> WhatsApp </span>
            <ArrowTopRightOnSquareIcon class="h-6 w-6" />
          </a>
          <div
            v-if="account === 'collection'"
            class="flex flex-row gap-x-2"
          >
            <span class="text-sm text-gray-200">
              {{
                conversationUser?.collectionAgentMessagesEnabled
                  ? "Desactivar agente"
                  : "Activar agente"
              }}
            </span>
            <base-switch
              color="green"
              label="Desactivar agente"
              size="xs"
              :model-value="conversationUser?.collectionAgentMessagesEnabled"
              @update:model-value="(e) => updateUser({ collectionAgentMessagesEnabled: e })"
            />
          </div>
        </div>
      </div>
      <crm-whatsapp-messages-box
        :conversation="fullConversation"
      />
      <v-form
        class="flex w-full flex-row gap-x-2 rounded-lg bg-white p-1 focus-within:ring-1 focus-within:ring-primary-600"
        @submit="sendReply"
      >
        <textarea
          v-model="message"
          name="message"
          class="textarea-no-outline w-full border-none bg-transparent p-1 text-left text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
        <base-button
          variant="white"
          text="Enviar"
          type="submit"
        />
      </v-form>
    </div>
  </div>
</template>

<style>
.textarea-no-outline:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
