import type { UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import { get, type GroupLoanApplication } from '@/api/groupLoanApplication';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

import type { ApiError } from '..';

type FetchGroupLoanApplicationComposableArgs = {
  id: MaybeRef<number>
} & UseQueryOptions<GroupLoanApplication, ApiError>;

function useFetchGroupLoanApplication({
  id, ...options
}: FetchGroupLoanApplicationComposableArgs) {
  return useReactiveQuery({
    queryKey: groupLoanApplicationKey(toRef(id)),
    queryFn: () => get(unref(id)).then(response => response.data.groupLoanApplication),
    ...options,
  });
}

export { useFetchGroupLoanApplication };
