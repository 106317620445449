<script setup lang="ts">
import { computed } from 'vue';

import type { ConsolidatedLoan, GroupLoanApplication } from '@/api/groupLoanApplication';
import { toCurrency, formatDate } from '@/utils/filters';

const props = defineProps<{
  groupLoanApplication: GroupLoanApplication;
  consolidatedLoans: ConsolidatedLoan[];
}>();

const groupMembers = computed(() => props.groupLoanApplication.memberLoanApplications.map(
  groupMemberLoanApplication => groupMemberLoanApplication.groupMember,
));

function findGroupMember(id?: number) {
  return groupMembers.value.find(member => member.id === id);
}

function findGroupMemberFromGroupMemberLoanApplicationId(id: number) {
  const groupMemberLoanApplication = props.groupLoanApplication.memberLoanApplications.find(mla => mla.id === id);

  return findGroupMember(groupMemberLoanApplication?.groupMemberId);
}

function formatMemberNames(ids: number[]) {
  return ids.map(id => findGroupMemberFromGroupMemberLoanApplicationId(id)?.user?.fullName).join(', ');
}
</script>

<template>
  <div class="flex flex-col gap-y-4 rounded-lg bg-white px-4 py-6 sm:px-6 lg:px-8">
    <h1 class="text-base font-semibold text-gray-900">
      Consolidaciones del grupo
    </h1>
    <div class="flex flex-col gap-y-4">
      <div
        v-for="consolidatedLoan in consolidatedLoans"
        :key="consolidatedLoan.groupMemberLoanApplicationIds.join('-')"
        class="flex flex-col gap-y-4 rounded-lg bg-gray-100 p-6"
      >
        <div class="flex flex-row justify-between">
          <div class="flex flex-col gap-y-1">
            <span class="text-sm font-semibold">
              {{ formatMemberNames(consolidatedLoan.groupMemberLoanApplicationIds) }}
            </span>
            <span class="text-sm">
              Ciclos: {{ consolidatedLoan.loans.length }}
            </span>
            <span class="text-sm">
              Fecha de apertura más reciente: {{
                formatDate(consolidatedLoan.mostRecentLoanStartDate, 'dd-MM-yyyy')
              }}
            </span>
            <span class="text-sm">
              Peor atraso: {{ consolidatedLoan.worstDelinquencyStrike }}
            </span>
          </div>
          <div
            v-if="consolidatedLoan.mostRecentDelinquentBalanceAmount > 0"
            class="flex flex-col gap-y-1"
          >
            <span class="text-sm font-bold text-red-600">
              Saldo vencido: {{ toCurrency(consolidatedLoan.mostRecentDelinquentBalanceAmount) }}
            </span>
            <span class="text-sm">
              Número de pagos vencidos: {{ consolidatedLoan.mostRecentDelinquentPaymentsCount }}
            </span>
            <span class="text-sm">
              Fecha de saldo vencido más reciente: {{ consolidatedLoan.mostRecentDelinquentBalanceDate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

