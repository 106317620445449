import { type MutationOptions, useQueryClient } from '@tanstack/vue-query';
import { unref, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import { create, type ResourceComment, type CreateCommentBody } from '@/api/comment';
import { commentsKey } from '@/api/queryKeys';
import useReactiveMutation from '@/composables/useReactiveMutation';

type UseCreateDocumentComposableArgs = MutationOptions<
ResourceComment, ApiError, Pick<CreateCommentBody, 'body' | 'attachmentIds'>
> & {
  resourceId: MaybeRef<ResourceComment['resourceId'] | null>;
  resourceType: MaybeRef<ResourceComment['resourceType']>;
};

function useCreateComment({ resourceId, resourceType, ...options }: UseCreateDocumentComposableArgs) {
  const queryClient = useQueryClient();

  return useReactiveMutation({
    ...options,
    mutationFn: ({ body, attachmentIds }) => {
      const unrefResourceId = unref(resourceId);

      if (unrefResourceId === null) {
        throw new Error('resourceId is required');
      }

      return create({
        body,
        attachmentIds,
        resourceId: unrefResourceId,
        resourceType: unref(resourceType),
      }).then((response) => response.data.comment);
    },
    onSuccess: (...args) => {
      options.onSuccess?.(...args);
      queryClient.invalidateQueries({ queryKey: commentsKey(resourceId, resourceType) });
    },
  });
}

export { useCreateComment };
