import { api } from '@/api/index';

function get({ phoneNumber, account }) {
  const path = `/api/internal/crm/whatsapp/conversations/${phoneNumber}?account=${account}`;

  return api({
    url: path,
  });
}

function sendReply({ recipientPhone, text, account }) {
  const path = `/api/internal/crm/whatsapp/replies?account=${account}`;

  return api({
    method: 'POST',
    url: path,
    data: { recipientPhone, text },
  });
}

function markAsRead({ phoneNumber, account }) {
  const path = `/api/internal/crm/whatsapp/conversations/${phoneNumber}/read?account=${account}`;

  return api({
    method: 'POST',
    url: path,
  });
}

export { get, sendReply, markAsRead };
