import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { ref, reactive, readonly, markRaw } from 'vue';

interface Banner {
  isVisible: boolean;
  message: string;
  color: string;
  position: 'top' | 'bottom';
  component: any | null;
}

interface Toast {
  id?: string;
  key?: string;
  message: string;
  type: 'info' | 'success' | 'error' | 'warning';
  position: 'top-right' | 'top' | 'bottom-right' | 'bottom';
  component: any | null;
  duration: number;
  icon: any | null;
  persist?: boolean;
}

const DEFAULT_BANNER: Banner = {
  isVisible: false,
  message: '',
  color: 'white',
  position: 'top',
  component: null,
};

const DEFAULT_TOAST: Toast = {
  message: '',
  type: 'info',
  position: 'top-right',
  component: null,
  duration: 5000,
  icon: null,
};

const REPEATED_TOAST_DELAY_MS = 250;

export default defineStore('ui', () => {
  const banner = reactive<Banner>({ ...DEFAULT_BANNER });

  function setBanner(options: Partial<Banner>) {
    Object.assign(banner, {
      component: options.component ? markRaw(options.component) : null,
      ...DEFAULT_BANNER,
      ...options,
    });
  }

  const toasts = ref<Toast[]>([]);

  function addToast(options: Toast) {
    toasts.value = toasts.value.filter(t => t.id !== options.id);
    const newToast: Toast = { key: uuidv4(), id: options.id || uuidv4(), ...DEFAULT_TOAST, ...options };
    toasts.value.unshift(newToast);
  }

  function toast(options: Partial<Toast> = {}) {
    const id = options.id || uuidv4();

    if (toasts.value.some(t => t.id === options.id)) {
      toasts.value = toasts.value.filter(t => t.id !== options.id);
      setTimeout(() => addToast({ id, ...options }), REPEATED_TOAST_DELAY_MS);
    } else {
      addToast({ id, ...options });
    }

    if (!options.persist) {
      setTimeout(
        () => {
          toasts.value = toasts.value.filter(t => t.id !== id);
        },
        options.duration || DEFAULT_TOAST.duration,
      );
    }
  }

  function closeToast(id: string) {
    toasts.value = toasts.value.filter(t => t.id !== id);
  }

  return {
    banner: readonly(banner),
    setBanner,
    toast,
    toasts,
    closeToast,
  };
});

