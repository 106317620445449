<script setup>
import { ref, computed, inject, nextTick } from 'vue';

import GroupInvitationsGrid from '@/components/group-invitations-grid.vue';
import WspHelp from '@/components/wsp-help.vue';
import { currentUserKey } from '@/utils/keys';

const props = defineProps({
  group: { type: Object, required: true },
  groupInvitation: { type: Object, required: true },
  otherPendingInvitations: { type: Array, default: () => [] },
  groupMembers: { type: Array, required: true },
  groupPresident: { type: Object, required: true },
});

const currentUser = inject(currentUserKey);
const formRef = ref(null);

const inviter = computed(() => props.groupInvitation.inviter);

const modalOpen = ref(false);
const action = ref(null);

function handleAccept() {
  action.value = 'accept';

  if (currentUser.value) {
    nextTick(() => formRef.value.innerFormRef.submit());
  } else {
    modalOpen.value = true;
  }
}

function handleReject() {
  action.value = 'decline';

  if (currentUser.value) {
    nextTick(() => formRef.value.innerFormRef.submit());
  } else {
    modalOpen.value = true;
  }
}
</script>

<template>
  <div class="container flex w-full max-w-xl flex-col items-center">
    <rails-flash-alerts />
    <div class="flex flex-row gap-x-4 rounded-xl bg-white p-4 sm:gap-x-6 sm:p-6">
      <span class="text-xl">
        🎉
      </span>
      <div class="flex flex-col gap-y-4">
        <span class="text-xs font-semibold sm:text-sm">
          Te han invitado al grupo <b>{{ group.name }}</b>
        </span>
        <p class="text-xs text-gray-700 sm:text-sm">
          <b>{{ inviter.firstName }}</b> te ha invitado a ser miembro de su grupo
          para solicitar un crédito grupal. Si no conoces a {{ inviter.firstName }}, te pedimos
          que rechaces la invitación.
        </p>
      </div>
    </div>
    <span class="mt-16 font-semibold sm:mt-20">
      Miembros del grupo
    </span>
    <group-invitations-grid
      :pending-invitations="otherPendingInvitations"
      :group-members="groupMembers"
      :group-president="groupPresident"
      class="mt-8"
    />
    <div class="mt-16 flex w-full flex-col gap-y-4 sm:mt-20">
      <v-form-native-wrapper
        ref="formRef"
        class="flex w-full flex-col gap-y-6"
        :action="`/group_invitations/${groupInvitation.invitationCode}/${action}`"
        method="POST"
      >
        <base-button
          type="button"
          text="Aceptar invitación"
          class="text-sm font-bold text-primary-600"
          @click="handleAccept()"
        />
        <button
          type="button"
          class="text-sm font-bold text-primary-600"
          @click="handleReject()"
        >
          Rechazar invitación
        </button>
      </v-form-native-wrapper>
    </div>
    <wsp-help class="mt-20" />
  </div>
  <base-modal
    :show="modalOpen"
    :title="`Deseas ${action === 'accept' ? 'unirte' : 'rechazar la invitación'} al grupo ${group.name}?`"
    :body="`Grupalia es una plataforma para pedir créditos grupales
      de forma 100% digital. <b>${inviter.firstName}</b> te ha invitado a ser miembro
      de su grupo.`"
    @close="modalOpen = false"
  >
    <template #actions>
      <v-form-native-wrapper
        v-slot="{ values }"
        class="flex w-full flex-col gap-y-6"
        :action="`/group_invitations/${groupInvitation.invitationCode}/${action}`"
        method="POST"
      >
        <div class="flex flex-col gap-y-2">
          <base-short-text-input
            name="verification_code"
            label="Código de verificación"
            description="Ingresa el código que te enviamos por WhatsApp para confirmar que aceptas la invitación."
          />
        </div>
        <base-button
          :text="action === 'accept' ? 'Aceptar invitación' : 'Rechazar invitación'"
          type="submit"
          class="w-full"
          :disabled="!values['verification_code']"
        />
      </v-form-native-wrapper>
    </template>
  </base-modal>
</template>
