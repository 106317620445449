/* eslint-disable max-statements */
import { useMutation, type UseMutationOptions } from '@tanstack/vue-query';
import { reactive, unref, watchEffect } from 'vue';

// Define proper generic types for useMutation (TData, TError, TVariables, TContext)
export default function useReactiveMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options: UseMutationOptions<TData, TError, TVariables, TContext>) {
  // Use the properly typed options in useMutation
  const mutationResult = useMutation(options);

  const reactiveMutationResult = reactive({
    data: unref(mutationResult.data),
    error: unref(mutationResult.error),
    isError: unref(mutationResult.isError),
    isIdle: unref(mutationResult.isIdle),
    isPending: unref(mutationResult.isPending),
    isPaused: unref(mutationResult.isPaused),
    isSuccess: unref(mutationResult.isSuccess),
    failureCount: unref(mutationResult.failureCount),
    failureReason: unref(mutationResult.failureReason),
    mutate: mutationResult.mutate, // Directly referencing as it's a function
    mutateAsync: mutationResult.mutateAsync, // Directly referencing as it's a function
    reset: mutationResult.reset, // Directly referencing as it's a function
    status: unref(mutationResult.status),
    submittedAt: unref(mutationResult.submittedAt),
    variables: unref(mutationResult.variables),
  });

  watchEffect(
    () => {
      if (mutationResult) {
        reactiveMutationResult.data = unref(mutationResult.data);
        reactiveMutationResult.error = unref(mutationResult.error);
        reactiveMutationResult.isError = unref(mutationResult.isError);
        reactiveMutationResult.isIdle = unref(mutationResult.isIdle);
        reactiveMutationResult.isPending = unref(mutationResult.isPending);
        reactiveMutationResult.isPaused = unref(mutationResult.isPaused);
        reactiveMutationResult.isSuccess = unref(mutationResult.isSuccess);
        reactiveMutationResult.failureCount = unref(mutationResult.failureCount);
        reactiveMutationResult.failureReason = unref(mutationResult.failureReason);
        reactiveMutationResult.status = unref(mutationResult.status);
        reactiveMutationResult.submittedAt = unref(mutationResult.submittedAt);
        reactiveMutationResult.variables = unref(mutationResult.variables);
      }
    },
  );

  return reactiveMutationResult;
}

