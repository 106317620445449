<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { useSessionStorage } from '@vueuse/core';
import { computed, reactive, onMounted } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import { type PreApprovePayload } from '@/api/groupLoanApplication/reviews';
import { usePreApproveGroupLoanApplication } from '@/api/groupLoanApplication/reviews/mutations';
import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import BaseSlideOver from '@/components/base-slide-over.vue';
import Comments from '@/components/comments.vue';
import useUIStore from '@/stores/ui';
import { toCurrency } from '@/utils/filters';
import LoanUtils from '@/utils/loanUtils';

interface Props {
  groupLoanApplication: GroupLoanApplication
  open: boolean;
}

interface Emits {
  (e: 'close'): void;
}

defineEmits<Emits>();
const props = defineProps<Props>();

const group = computed(() => props.groupLoanApplication.group);

const uiStore = useUIStore();

const initialEditableGroupInfo = props.groupLoanApplication.memberLoanApplications
  .reduce((acc, app) => {
    acc[`group-member-loan-application-${app.id}`] = {
      meetsLoanConditions: app.meetsLoanConditions,
      consolidated: app.consolidated,
      acceptedAmount: app.acceptedAmount,
      interestRate: app.interestRate,
      preApproved: app.preApproved,
    };

    return acc;
  }, {} as Record<string, {
    meetsLoanConditions: boolean;
    consolidated: boolean;
    acceptedAmount: number | null;
    interestRate: number;
    preApproved: boolean;
  }>);

const groupLoanApplicationEditableInfo = useSessionStorage(
  `gla-${props.groupLoanApplication.id}-editable-info`,
  initialEditableGroupInfo,
);

onMounted(() => {
  props.groupLoanApplication.memberLoanApplications.forEach(app => {
    if (app.preApproved) {
      const key = `group-member-loan-application-${app.id}`;
      groupLoanApplicationEditableInfo.value[key] = {
        ...groupLoanApplicationEditableInfo.value[key],
        meetsLoanConditions: app.meetsLoanConditions,
        consolidated: app.consolidated,
        acceptedAmount: app.acceptedAmount,
        interestRate: app.interestRate,
        preApproved: true,
      };
    }
  });
});

// eslint-disable-next-line max-statements, complexity
function computeSlideOverItems(
  groupMemberLoanApplication: GroupMemberLoanApplication,
  slideOverItems: Record<string, string | number | null>,
) {
  const editableInfo = groupLoanApplicationEditableInfo.value[
    `group-member-loan-application-${groupMemberLoanApplication.id}`
  ];

  const groupMember = props.groupLoanApplication.group.members.find(
    member => member.id === groupMemberLoanApplication.groupMemberId,
  );

  let periodPayment = null;

  if (editableInfo.acceptedAmount) {
    periodPayment = Math.ceil(LoanUtils.calculateLoanPayment(
      editableInfo.acceptedAmount,
      editableInfo.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    ));
  }

  if (groupMember) {
    slideOverItems.Monto = groupMemberLoanApplication.amount && toCurrency(groupMemberLoanApplication.amount);
    slideOverItems.Rol = groupMember.humanRole;
    slideOverItems.Ingresos = groupMemberLoanApplication.income && toCurrency(groupMemberLoanApplication.income);
    slideOverItems.Gastos = groupMemberLoanApplication.expenses && toCurrency(groupMemberLoanApplication.expenses);
    slideOverItems['Monto disponible para crédito'] = groupMemberLoanApplication.loanPaymentAbility && toCurrency(
      groupMemberLoanApplication.loanPaymentAbility,
    );
    slideOverItems['Capacidad de pago interna'] = groupMemberLoanApplication.internalLoanPaymentAbility;
    slideOverItems['Costo por mil'] = LoanUtils.costPerThousand(
      editableInfo.interestRate,
      props.groupLoanApplication.repaymentPeriods,
    );
    slideOverItems['Pago semanal'] = periodPayment && toCurrency(periodPayment);
    slideOverItems.Peso = ((editableInfo.acceptedAmount || 0) / Object.values(groupLoanApplicationEditableInfo.value)
      .map(info => info.acceptedAmount || 0)
      .reduce((acc, amount) => acc + amount, 0))
      .toFixed(2); // eslint-disable-line no-magic-numbers
    slideOverItems['Periodos / Monto disp. para crédito'] = periodPayment &&
      groupMemberLoanApplication.loanPaymentAbility && (
      periodPayment / groupMemberLoanApplication.loanPaymentAbility
    ).toFixed(2); // eslint-disable-line no-magic-numbers
  }
}

const groupMemberLoanApplicationsComputedInfoWithLabels = computed(
  () => props.groupLoanApplication.memberLoanApplications
    .map(
      groupMemberLoanApplication => {
        const slideOverItems = {};

        computeSlideOverItems(groupMemberLoanApplication, slideOverItems);

        return {
          ...groupMemberLoanApplication,
          slideOverItems,
        };
      },
    ),
);

const groupTotalLoanAmount = computed(
  () => Object.values(groupLoanApplicationEditableInfo.value)
    .filter(info => info.preApproved)
    .reduce((total, applicationInfo) => total + (applicationInfo.acceptedAmount || 0), 0),
);

const groupPeriodPayment = computed(
  () => Object.values(groupLoanApplicationEditableInfo.value)
    .filter(info => info.preApproved)
    .reduce(
      (total, applicationInfo) => total +
        Math.ceil(LoanUtils.calculateLoanPayment(
          applicationInfo.acceptedAmount || 0,
          applicationInfo.interestRate,
          props.groupLoanApplication.repaymentPeriods,
        )),
      0,
    ),
);

const weightedAverageInterestRate = computed(
  () => {
    const totalWeight = Object.values(groupLoanApplicationEditableInfo.value)
      .filter(info => info.preApproved)
      .map(info => info.acceptedAmount || 0)
      .reduce((sum, amount) => sum + amount, 0);

    return Object.values(groupLoanApplicationEditableInfo.value)
      .filter(info => info.preApproved)
      .map(info => (info.interestRate) * ((info.acceptedAmount || 0) / totalWeight))
      .reduce((acc, rate) => acc + rate, 0);
  },
);

const preApproveMutation = usePreApproveGroupLoanApplication({
  id: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitudes pre-aprobadas',
      type: 'success',
      position: 'top',
    });
  },
});

function preApprove() {
  const formData = reactive<PreApprovePayload>({
    'group_member_loan_application': {},
  });

  props.groupLoanApplication.memberLoanApplications.forEach(groupMemberLoanApplication => {
    const editableInfo = groupLoanApplicationEditableInfo.value[
      `group-member-loan-application-${groupMemberLoanApplication.id}`
    ];

    formData.group_member_loan_application[groupMemberLoanApplication.id] = {
      'accepted_amount': editableInfo.acceptedAmount || groupMemberLoanApplication.amount || 0,
      'interest_rate': editableInfo.interestRate,
      'pre_approved': editableInfo.preApproved,
      'meets_loan_conditions': editableInfo.meetsLoanConditions,
      'consolidated': editableInfo.consolidated,
    };
  });

  preApproveMutation.mutate(formData);
}

</script>

<template>
  <base-slide-over
    :open="open"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-y-8 p-2">
      <div class="flex flex-col rounded-md bg-gray-100 p-4">
        <h1 class="text-sm font-semibold">
          {{ group.name }}
        </h1>
        <h2 class="text-xs text-gray-500">
          {{ group.publicId }}
        </h2>
      </div>
      <div class="flex flex-col gap-y-4">
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Monto total
          </span>
          <span class="text-sm text-gray-600">
            {{ groupTotalLoanAmount }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Pago por periodo del grupo
          </span>
          <span class="text-sm text-gray-600">
            {{ groupPeriodPayment }}
          </span>
        </div>
        <div class="grid grid-cols-2">
          <span class="text-sm font-semibold">
            Tasa promedio ponderada
          </span>
          <span class="text-sm text-gray-600">
            {{ (weightedAverageInterestRate * 100).toFixed(2) }}%
          </span>
        </div>
      </div>
      <div>
        <ul class="flex flex-col overflow-y-scroll">
          <li
            v-for="(groupMemberLoanApplication, index) in groupMemberLoanApplicationsComputedInfoWithLabels"
            :key="index"
            class="py-2"
          >
            <Disclosure v-slot="{ open: disclosureOpen }">
              <DisclosureButton
                class="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75"
              >
                <span>
                  {{ groupMemberLoanApplication.groupMember.user.fullName }}
                </span>
                <ChevronUpIcon
                  :class="disclosureOpen ? 'rotate-180 transform' : ''"
                  class="h-5 w-5 text-gray-500"
                />
              </DisclosureButton>
              <DisclosurePanel>
                <div class="flex flex-col gap-y-8 px-8">
                  <ul>
                    <li
                      v-for="(value, label) in groupMemberLoanApplication.slideOverItems"
                      :key="label"
                      class="grid grid-cols-2 border-b border-gray-200 py-4"
                    >
                      <span class="text-sm font-semibold">
                        {{ label }}
                      </span>
                      <span class="text-sm text-gray-600">
                        {{ value }}
                      </span>
                    </li>
                    <li class="grid grid-cols-2 items-center py-4">
                      <span class="text-sm font-semibold">
                        Monto aceptado
                      </span>
                      <base-short-text-input
                        :name="`acceptedAmount-${groupMemberLoanApplication.id}`"
                        type="number"
                        placeholder="Monto aceptado"
                        :value="groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].acceptedAmount"
                        @input="e => groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].acceptedAmount = parseInt(e.target.value, 10) || 0"
                      />
                    </li>
                    <li class="grid grid-cols-2 items-center py-4">
                      <span class="text-sm font-semibold">
                        Tasa de interés
                      </span>
                      <base-short-text-input
                        :name="`interestRate-${groupMemberLoanApplication.id}`"
                        placeholder="Tasa de interés"
                        :value="groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].interestRate"
                        @input="e => groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].interestRate = parseFloat(e.target.value) || 0"
                      />
                    </li>
                    <li class="grid grid-cols-2 items-center py-4">
                      <span class="text-sm font-semibold">
                        Cumple políticas de crédito
                      </span>
                      <base-checkbox
                        v-model="groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].meetsLoanConditions"
                        :name="`meetsLoanConditions-${groupMemberLoanApplication.id}`"
                      />
                    </li>
                    <li class="grid grid-cols-2 items-center py-4">
                      <span class="text-sm font-semibold">
                        Integrante consolidado
                      </span>
                      <base-checkbox
                        v-model="groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].consolidated"
                        :name="`meetsLoanCondition-${groupMemberLoanApplication.id}`"
                      />
                    </li>
                    <li class="grid grid-cols-2 items-center py-4">
                      <span class="text-sm font-semibold">
                        Pre-aprobar solicitud
                      </span>
                      <base-checkbox
                        v-model="groupLoanApplicationEditableInfo[
                          `group-member-loan-application-${groupMemberLoanApplication.id}`
                        ].preApproved"
                        :name="`preApproved-${groupMemberLoanApplication.id}`"
                      />
                    </li>
                  </ul>
                  <comments
                    name="comment[body]"
                    comments-height-class="h-40"
                    resource-type="GroupMemberLoanApplication"
                    :resource-id="groupMemberLoanApplication.id"
                  />
                </div>
              </DisclosurePanel>
            </Disclosure>
          </li>
        </ul>
      </div>
      <base-button
        v-if="groupLoanApplication.aasmState === 'application_in_review'"
        class="w-full"
        text="Pre-aprobar solicitudes seleccionadas"
        :loading="preApproveMutation.isPending"
        @click="preApprove"
      />
    </div>
  </base-slide-over>
</template>
