<script setup>
import { reactive, inject } from 'vue';

import { mobileAppStoreUrlKey } from '@/utils/keys';

import CreditSimulator from './credit-simulator.vue';

const mobileAppStoreUrl = inject(mobileAppStoreUrlKey);

const loan = reactive({
  amount: 10000,
  payments: 16,
});
</script>

<template>
  <div class="mx-auto max-w-7xl py-16 md:grid md:grid-cols-2 md:gap-x-8 md:px-8 md:py-32 lg:gap-x-16">
    <div class="lg:pt-4">
      <div class="mx-auto max-w-2xl">
        <div class="md:max-w-xl">
          <h1 class="mx-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Un crédito grupal
            <span class="text-primary-600">fácil</span>
            y <span class="text-primary-600">100% en línea</span>
          </h1>
          <p class="mx-4 mt-6 leading-8 text-gray-700 sm:text-lg">
            Obtén un préstamo para invertir en tu negocio sin
            necesidad de promotores, hacer filas, ni salir de tu casa.
          </p>
          <div class="mt-6 flex w-full px-4 sm:hidden">
            <base-button
              text="Empezar solicitud"
              type="button"
              class="w-full"
              :href="mobileAppStoreUrl"
            />
          </div>
          <credit-simulator
            v-model:loan="loan.amount"
            v-model:payments="loan.payments"
            show-payments-options
            class="mt-8"
          >
            <template #cta>
              <base-button
                text="Solicitar crédito"
                class="mt-8"
                type="button"
                :href="mobileAppStoreUrl"
              />
            </template>
          </credit-simulator>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="mt-12 w-full grow self-start overflow-hidden md:mt-0 md:rounded-xl md:border md:border-primary-600">
        <base-image
          img-name="landing-header-woman"
          alt="Mujer emprendedora dueña de un negocio"
          ext="png"
          class="h-full w-full object-cover"
        />
      </div>
      <span class="self-center text-xs text-primary-600">
        Alicia, 7 ciclos con Grupalia.
      </span>
    </div>
  </div>
</template>
