<script setup lang="ts">
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/24/outline';
import { compareAsc } from 'date-fns';
import { ref, computed, watch, nextTick, toRef } from 'vue';

import type { ResourceComment } from '@/api/comment';
import { useCreateComment } from '@/api/comment/mutations';
import { useFetchComments } from '@/api/comment/queries';
import BaseComment from '@/components/base-comment.vue';
import StyledTrixEditor from '@/components/styled-trix-editor.vue';

interface Props {
  title?: string | null;
  resourceId: number | null;
  resourceType: string;
  canWrite?: boolean;
  name?: string;
  commentsHeightClass?: string;
}
const props = withDefaults(defineProps<Props>(), {
  title: null,
  resourceId: null,
  canWrite: true,
  name: 'comments',
  commentsHeightClass: 'h-96',
});

interface Emits {
 (e: 'new-comment', comment: ResourceComment): void;
}
const emit = defineEmits<Emits>();

const commentsContainerRef = ref<HTMLElement | null>(null);

const fetchCommentsQ = useFetchComments({
  resourceType: toRef(props, 'resourceType'),
  resourceId: toRef(props, 'resourceId'),
});

const sortedComments = computed(() => [...(fetchCommentsQ.data || [])].sort(
  (a, b) => compareAsc(new Date(b.createdAt), new Date(a.createdAt)),
) || []);

watch(sortedComments, () => {
  nextTick(() => {
    if (!commentsContainerRef.value) return;

    commentsContainerRef.value.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
});

const newComment = ref('');
const attachmentIds = ref<number[]>([]);
const submittedAttachmentIds = ref<number[]>([]);

const createCommentMutation = useCreateComment({
  resourceId: toRef(props, 'resourceId'),
  resourceType: toRef(props, 'resourceType'),
  onSuccess: (comment) => {
    emit('new-comment', comment);
    submittedAttachmentIds.value = attachmentIds.value;
    attachmentIds.value = [];
    newComment.value = '';
    // this is used because the files are removed if the comment is set to empty
    // so we need to tell the remove handler to not remove this attached files
    setTimeout(() => {
      submittedAttachmentIds.value = [];
    // eslint-disable-next-line no-magic-numbers
    }, 100);
  },
});
</script>

<template>
  <div class="flex flex-col gap-y-3">
    <h2 class="flex items-center">
      <ChatBubbleBottomCenterTextIcon
        class="mr-3 h-6 w-6 text-gray-700"
      />
      <span class="text-lg text-gray-700">
        {{ title || 'Comentarios' }}
      </span>
    </h2>
    <div
      ref="commentsContainerRef"
      :class="['mb-6 overflow-y-scroll rounded-lg bg-gray-100 p-2', commentsHeightClass]"
      style="resize: vertical;"
    >
      <div
        v-if="sortedComments.length === 0"
        class="text-base text-gray-100"
      >
        No hay comentarios
      </div>
      <div
        v-else
        class="space-y-2"
      >
        <base-comment
          v-for="comment in sortedComments"
          :key="comment.id"
          :comment="comment"
        />
      </div>
    </div>
    <div
      v-if="canWrite"
      class="flex flex-col space-y-2"
    >
      <styled-trix-editor
        v-model="newComment"
        :submitted-attachment-ids="submittedAttachmentIds"
        :name="name"
        v-bind="$attrs"
        @add-attachment-id="(id) => attachmentIds = [...attachmentIds, id]"
        @remove-attachment-id="(id) => attachmentIds = attachmentIds.filter((attachmentId) => attachmentId !== id)"
      />
      <base-button
        class="ml-auto mt-2"
        text="Enviar"
        :loading="createCommentMutation.isPending"
        :disabled="!newComment"
        @click="createCommentMutation.mutate({ body: newComment, attachmentIds })"
      />
    </div>
  </div>
</template>
