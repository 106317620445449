<script setup>
import { storeToRefs } from 'pinia';
import { computed, inject } from 'vue';

import useUIStore from '@/stores/ui';
import { screenKey } from '@/utils/keys';

import BaseToast from './base-toast.vue';

const TRANSITIONS = {
  top: {
    enterActive: 'transition-all duration-300 ease-out-quad',
    leaveActive: 'transition-all ease-in-quad',
    enter: 'opacity-0 scale-70',
    enterFrom: 'opacity-0 scale-70 -translate-y-8',
    enterTo: 'scale-100 opacity-100',
    leave: 'scale-100 opacity-100',
    leaveTo: 'opacity-0 scale-70',
  },
  bottom: {
    enterActive: 'transition-all duration-300 ease-out-quad',
    leaveActive: 'transition-all ease-in-quad',
    enter: 'opacity-0 scale-70',
    enterFrom: 'opacity-0 scale-70 translate-y-8',
    enterTo: 'scale-100 opacity-100',
    leave: 'scale-100 opacity-100',
    leaveTo: 'opacity-0 scale-70',
  },
  topRight: {
    enterActive: 'transition-all duration-300 ease-out-quad',
    leaveActive: 'transition-all ease-in-quad',
    enter: 'opacity-0 scale-70',
    enterFrom: 'opacity-0 scale-70 translate-x-8',
    enterTo: 'scale-100 opacity-100',
    leave: 'scale-100 opacity-100',
    leaveTo: 'opacity-0 scale-70',
  },
  bottomRight: {
    enterActive: 'transition-all duration-300 ease-out-quad',
    leaveActive: 'transition-all ease-in-quad',
    enter: 'opacity-0 scale-70',
    enterFrom: 'opacity-0 scale-70 translate-y-8',
    enterTo: 'scale-100 opacity-100',
    leave: 'scale-100 opacity-100',
    leaveTo: 'opacity-0 scale-70',
  },
};

const uiStore = useUIStore();
const { toasts } = storeToRefs(uiStore);
const screen = inject(screenKey);
const toastPositions = computed(() => {
  let positions;

  if (screen.isMobile || screen.isTablet) {
    positions = [
      {
        position: 'top',
        class: 'top-20 inset-x-auto',
        toasts: toasts.value.filter(t => t.position.includes('top')),
        transitions: TRANSITIONS.top,
      },
      {
        position: 'bottom',
        class: 'bottom-12 inset-x-auto',
        toasts: toasts.value.filter(t => t.position.includes('bottom')).reverse(),
        transitions: TRANSITIONS.bottom,
      },
    ];
  } else {
    positions = [
      {
        position: 'top',
        class: 'top-16 inset-x-0',
        toasts: toasts.value.filter(t => t.position === 'top'),
        transitions: TRANSITIONS.top,
      },
      {
        position: 'bottom',
        class: 'bottom-12 inset-x-0',
        toasts: toasts.value.filter(t => t.position === 'bottom').reverse(),
        transitions: TRANSITIONS.bottom,
      },
      {
        position: 'top-right',
        class: 'top-16 right-12',
        toasts: toasts.value.filter(t => t.position === 'top-right'),
        transitions: TRANSITIONS.topRight,
      },
      {
        position: 'bottom-right',
        class: 'bottom-12 right-12',
        toasts: toasts.value.filter(t => t.position === 'bottom-right').reverse(),
        transitions: TRANSITIONS.bottomRight,
      },
    ];
  }

  return positions;
});
</script>

<template>
  <transition-group
    v-for="position in toastPositions"
    :key="position.position"
    :enter-active-class="position.transitions.enterActive"
    :leave-active-class="position.transitions.leaveActive"
    :enter-class="position.enter"
    :enter-from-class="position.transitions.enterFrom"
    :enter-to-class="position.transitions.enterTo"
    :leave-class="position.transitions.leave"
    :leave-to-class="position.transitions.leaveTo"
    :name="`toasts-${position.position}`"
    tag="div"
    class="fixed z-30 flex flex-col items-center space-y-2 sm:space-y-3"
    :class="position.class"
  >
    <base-toast
      v-for="(toast, index) in position.toasts"
      :key="toast.key"
      :index="index"
      :message="toast.message"
      :type="toast.type"
      :component="toast.component"
      :duration="toast.duration"
      :icon="toast.icon"
    />
  </transition-group>
</template>

<style>
.toasts-top-move,
.toasts-bottom-move,
.toasts-top-right-move,
.toasts-bottom-right-move
{
  @apply transition-all duration-300;
}
</style>
