import { type MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { update, updateAvatar, type IUser } from '@/api/currentUser';
import useReactiveMutation from '@/composables/useReactiveMutation';

type UseUpdateCurrentUserComposableArgs = MutationOptions<IUser, ApiError, Partial<IUser & { removeAvatar: boolean }>>;

function useUpdateCurrentUser(options: UseUpdateCurrentUserComposableArgs) {
  return useReactiveMutation({
    ...options,
    mutationFn: (user) => update(user),
  });
}

type UseUpdateCurrentUserAvatarComposableArgs = MutationOptions<IUser, ApiError, File>

function useUpdateCurrentUserAvatar(options: UseUpdateCurrentUserAvatarComposableArgs = {}) {
  return useReactiveMutation({
    ...options,
    mutationFn: (avatar) => updateAvatar(avatar),
  });
}

export { useUpdateCurrentUser, useUpdateCurrentUserAvatar };
