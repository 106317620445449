import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';

import type { IUser } from '@/api/currentUser';

export default defineStore('user', () => {
  const currentUser = ref<IUser | null>(null);

  function setCurrentUser(user: IUser) {
    currentUser.value = user;
  }

  return {
    currentUser: readonly(currentUser),
    setCurrentUser,
  };
});
