const MS_IN_SECOND = 1000;
const SECOND_IN_MINUTE = 60;
const MS_IN_MINUTE = SECOND_IN_MINUTE * MS_IN_SECOND;
const MINUTE_IN_HOUR = 60;
const MS_IN_HOUR = MINUTE_IN_HOUR * MS_IN_MINUTE;
const HOUR_IN_DAY = 24;
const MS_IN_DAY = HOUR_IN_DAY * MS_IN_HOUR;
const DAY_IN_WEEK = 7;
const MS_IN_WEEK = DAY_IN_WEEK * MS_IN_DAY;

export {
  MS_IN_SECOND,
  MS_IN_MINUTE,
  MS_IN_HOUR,
  MS_IN_DAY,
  MS_IN_WEEK,
};
