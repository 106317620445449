import { api } from '@/api/index';

interface Document {
  id: number;
  label: 'comment_attachment';
  name: string;
  fileUrl: string;
}

type CreateDocumentFormData = Partial<Pick<Document, 'label' | 'name'>> & {
  documentableId?: number;
  documentableType?: string;
  file?: File;
}

const basePath = '/api/internal/documents';

type IndexArgs = { documentableId: number; documentableType: string };
function index({ documentableId, documentableType }: IndexArgs) {
  return api.get<{ documents: Document[] }>(
    basePath,
    { params: { documentableId, documentableType } },
  );
}

function show(id: number) {
  return api.get<{ document: Document }>(`${basePath}/${id}`);
}

function create(data: CreateDocumentFormData) {
  const formData = new FormData();
  if (data.file) formData.append('document[file]', data.file);
  if (data.label) formData.append('document[label]', data.label);
  if (data.name) formData.append('document[name]', data.name);
  if (data.documentableId) formData.append('document[documentable_id]', data.documentableId.toString());
  if (data.documentableType) formData.append('document[documentable_type]', data.documentableType);

  return api.post<{ document: Document }>(
    basePath,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
}

function destroy(id: number) {
  return api.delete<void>(`${basePath}/${id}`);
}

export type { Document, CreateDocumentFormData };

export { index, show, create, destroy };
