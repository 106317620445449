import * as Sentry from '@sentry/vue';
import { MutationCache, QueryClient, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { es } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import 'trix/dist/trix.css';
import FloatingVue from 'floating-vue';
import { camelizeKeys } from 'humps';
import { createPinia } from 'pinia';
import posthog from 'posthog-js';
import Trix from 'trix';
import { createApp, provide, computed, onBeforeMount } from 'vue';
import { createI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';

import AppDownloadOverlay from '@/components/app-download-overlay.vue';
import BaseAvatar from '@/components/base-avatar.vue';
import BaseBackButton from '@/components/base-back-button.vue';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseCheckbox from '@/components/base-checkbox.vue';
import BaseDateInput from '@/components/base-date-input.vue';
import BaseDatePicker from '@/components/base-date-picker.vue';
import BaseEmptyState from '@/components/base-empty-state.vue';
import BaseFileInput from '@/components/base-file-input.vue';
import BaseFormAnswers from '@/components/base-form-answers.vue';
import BaseFormGenerator from '@/components/base-form-generator.vue';
import BaseFormSection from '@/components/base-form-section.vue';
import BaseHorizontalProgressBar from '@/components/base-horizontal-progress-bar.vue';
import BaseIconBox from '@/components/base-icon-box.vue';
import BaseIcon from '@/components/base-icon.vue';
import BaseImage from '@/components/base-image.vue';
import BaseLongTextInput from '@/components/base-long-text-input.vue';
import BaseModal from '@/components/base-modal.vue';
import BaseNativeSelect from '@/components/base-native-select.vue';
import BasePhoneInput from '@/components/base-phone-input.vue';
import BaseShortTextInput from '@/components/base-short-text-input.vue';
import BaseSlider from '@/components/base-slider.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import BaseSwitch from '@/components/base-switch.vue';
import BreakpointsHelper from '@/components/breakpoints-helper.vue';
import RailsFlashAlerts from '@/components/rails-flash-alerts.vue';
import TheAppLayout from '@/components/the-app-layout.vue';
import TheCollectionLayout from '@/components/the-collection-layout.vue';
import TheCrmLayout from '@/components/the-crm-layout.vue';
import TheReviewsLayout from '@/components/the-reviews-layout.vue';
import VFormNativeWrapper from '@/components/v-form-native-wrapper.vue';
import VForm from '@/components/v-form.vue';
import WhatsappChatButton from '@/components/whatsapp-chat-button.vue';
import useScreen from '@/composables/useScreen';
import ClickOutside from '@/directives/clickOutside';
import 'floating-vue/dist/style.css';
import '@/css/application.css';
import esLocales from '@/locales/es';
import useUserStore from '@/stores/user';
import {
  abbreviateNumber,
  formatNumber,
  toCurrency,
  formatDate,
  formatTime,
  formatRelativeDate,
} from '@/utils/filters';
import {
  landingOriginKey,
  wspSupportNumberKey,
  flashMessagesKey,
  currentUserKey,
  screenKey,
  mobileAppStoreUrlKey,
} from '@/utils/keys';
import '@/utils/yupConfig';
import storeUrl from '@/utils/storeUrl';

import CollectionGroupLoansIndex from '../views/collection/group-loans/index.vue';
import CrmWhatsappConversationsIndex from '../views/crm/whatsapp/conversations/index.vue';
import GroupApplicationShow from '../views/group/application/show.vue';
import GroupRolesEdit from '../views/group/roles/edit.vue';
import GroupInvitationsNew from '../views/group-invitations/new.vue';
import GroupInvitationsShow from '../views/group-invitations/show.vue';
import GroupLoanGroupLoanPaymentsIndex from '../views/group-loan/group-loan-payments/index.vue';
import GroupLoanShow from '../views/group-loan/show.vue';
import GroupMemberLoanApplicationBankAccountEdit from '../views/group-member-loan-application/bank-account/edit.vue';
import GroupMemberLoanApplicationContractShow from '../views/group-member-loan-application/contract/show.vue';
import GroupMemberLoanApplicationFormEdit from '../views/group-member-loan-application/form/edit.vue';
import GroupMemberLoanApplicationKycImagesEdit from '../views/group-member-loan-application/kyc-images/edit.vue';
import GroupMemberLoanApplicationKycReferencesNew from '../views/group-member-loan-application/kyc-references/new.vue';
import GroupMemberLoanApplicationNewMembersApprovalNew
  from '../views/group-member-loan-application/new-members-approval/new.vue';
import GroupMemberLoanApplicationPlatformEdit from '../views/group-member-loan-application/platform/edit.vue';
import GroupMemberLoanApplicationsCurpAndAddressFormsShow from
  '../views/group-member-loan-applications/curp-and-address-forms/show.vue';
import GroupsNew from '../views/groups/new.vue';
import LandingIndex from '../views/landing/index.vue';
import PromotersGroupLoanApplicationsIndex from '../views/promoters/group-loan-applications/index.vue';
import PromotersGroupLoansIndex from '../views/promoters/group-loans/index.vue';
import PromotersHomeIndex from '../views/promoters/home/index.vue';
import ReferralsNew from '../views/referrals/new.vue';
import ReviewsGroupLoanApplicationsIndex from '../views/reviews/group-loan-applications/index.vue';
import ReviewsGroupLoanApplicationsShow from '../views/reviews/group-loan-applications/show.vue';
import UsersProfileShow from '../views/users/profile/show.vue';
import UsersRegistrationsNew from '../views/users/registrations/new.vue';
import UsersSessionsNew from '../views/users/sessions/new.vue';

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  messages: { es: esLocales },
});

setDefaultOptions({ locale: es });

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {},
    // eslint-disable-next-line max-statements
    setup() {
      const LANDING_DOMAIN = window.env.LANDING_DOMAIN;

      provide(
        landingOriginKey,
        (LANDING_DOMAIN && `https://${LANDING_DOMAIN}`) || window.location.origin,
      );
      provide(wspSupportNumberKey, window.env.WSP_SUPPORT_NUMBER);
      provide(flashMessagesKey, window.flashMessages);
      provide(mobileAppStoreUrlKey, storeUrl);

      const userStore = useUserStore();
      provide(currentUserKey, computed(() => userStore.currentUser));

      onBeforeMount(() => {
        if (window.currentUser) {
          userStore.setCurrentUser(window.currentUser);
          if (window.env.SENTRY_DSN && window.env.PRODUCTION) {
            Sentry.setUser({
              id: userStore.currentUser.id,
              phone: userStore.currentUser.phoneNumber,
              fullName: userStore.currentUser.fullName,
            });
          }

          if (window.env.POSTHOG_API_KEY && window.env.PRODUCTION) {
            posthog.identify(
              userStore.currentUser.id.toString(),
              {
                id: userStore.currentUser.id,
                phone: userStore.currentUser.phoneNumber,
                fullName: userStore.currentUser.fullName,
              },
            );
          }
        }
      });

      const { screen } = useScreen();
      provide(screenKey, screen);
    },
  });

  if (window.env.PRODUCTION) {
    if (window.env.SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: window.env.SENTRY_DSN,
        integrations: [
          new posthog.SentryIntegration(posthog, 'Reality', window.env.POSTHOG_PROJECT_ID),
        ],
      });
    }

    if (window.env.POSTHOG_API_KEY) {
      posthog.init(window.env.POSTHOG_API_KEY, {
        'session_recording': {
          maskAllInputs: true, // Important - this needs to be true for the below function to be called
          maskInputFn: (text, element) => {
            if (element?.attributes.type?.value === 'password') {
              return '*'.repeat(text.length);
            }

            return text;
          },
        },
      });
    }
  }

  app.config.globalProperties.$filters = {
    abbreviateNumber,
    formatNumber,
    camelizeKeys,
    toCurrency,
    formatDate,
    formatTime,
    formatRelativeDate,
  };

  // views
  app.component('LandingIndex', LandingIndex);
  app.component('UsersSessionsNew', UsersSessionsNew);
  app.component('UsersRegistrationsNew', UsersRegistrationsNew);
  app.component('GroupsNew', GroupsNew);
  app.component('GroupApplicationShow', GroupApplicationShow);
  app.component('GroupInvitationsNew', GroupInvitationsNew);
  app.component('GroupInvitationsShow', GroupInvitationsShow);
  app.component('GroupMemberLoanApplicationFormEdit', GroupMemberLoanApplicationFormEdit);
  app.component('GroupMemberLoanApplicationKycImagesEdit', GroupMemberLoanApplicationKycImagesEdit);
  app.component('GroupMemberLoanApplicationKycReferencesNew', GroupMemberLoanApplicationKycReferencesNew);
  app.component('GroupMemberLoanApplicationContractShow', GroupMemberLoanApplicationContractShow);
  app.component('GroupMemberLoanApplicationNewMembersApprovalNew', GroupMemberLoanApplicationNewMembersApprovalNew);
  app.component('GroupMemberLoanApplicationBankAccountEdit', GroupMemberLoanApplicationBankAccountEdit);
  app.component('ReviewsGroupLoanApplicationsShow', ReviewsGroupLoanApplicationsShow);
  app.component('ReviewsGroupLoanApplicationsIndex', ReviewsGroupLoanApplicationsIndex);
  app.component('GroupLoanShow', GroupLoanShow);
  app.component('UsersProfileShow', UsersProfileShow);
  app.component('CrmWhatsappConversationsIndex', CrmWhatsappConversationsIndex);
  app.component('GroupMemberLoanApplicationPlatformEdit', GroupMemberLoanApplicationPlatformEdit);
  app.component('CollectionGroupLoansIndex', CollectionGroupLoansIndex);
  app.component(
    'GroupMemberLoanApplicationsCurpAndAddressFormsShow',
    GroupMemberLoanApplicationsCurpAndAddressFormsShow,
  );
  app.component('PromotersGroupLoanApplicationsIndex', PromotersGroupLoanApplicationsIndex);
  app.component('PromotersHomeIndex', PromotersHomeIndex);
  app.component('PromotersGroupLoansIndex', PromotersGroupLoansIndex);
  app.component('ReferralsNew', ReferralsNew);
  app.component('GroupRolesEdit', GroupRolesEdit);
  app.component('GroupLoanGroupLoanPaymentsIndex', GroupLoanGroupLoanPaymentsIndex);

  // components
  app.component('WhatsappChatButton', WhatsappChatButton);
  app.component('BreakpointsHelper', BreakpointsHelper);
  app.component('VForm', VForm);
  app.component('VFormNativeWrapper', VFormNativeWrapper);
  app.component('TheAppLayout', TheAppLayout);
  app.component('TheReviewsLayout', TheReviewsLayout);
  app.component('TheCrmLayout', TheCrmLayout);
  app.component('TheCollectionLayout', TheCollectionLayout);
  app.component('BaseButton', BaseButton);
  app.component('BaseBackButton', BaseBackButton);
  app.component('BaseImage', BaseImage);
  app.component('BaseShortTextInput', BaseShortTextInput);
  app.component('BaseLongTextInput', BaseLongTextInput);
  app.component('BaseSlider', BaseSlider);
  app.component('BaseSpinner', BaseSpinner);
  app.component('BaseIconBox', BaseIconBox);
  app.component('RailsFlashAlerts', RailsFlashAlerts);
  app.component('BaseAvatar', BaseAvatar);
  app.component('BaseFormSection', BaseFormSection);
  app.component('BaseFormGenerator', BaseFormGenerator);
  app.component('BaseHorizontalProgressBar', BaseHorizontalProgressBar);
  app.component('BaseModal', BaseModal);
  app.component('BaseNativeSelect', BaseNativeSelect);
  app.component('BasePhoneInput', BasePhoneInput);
  app.component('BaseDatePicker', BaseDatePicker);
  app.component('BaseDateInput', BaseDateInput);
  app.component('BaseCheckbox', BaseCheckbox);
  app.component('BaseFileInput', BaseFileInput);
  app.component('BaseIcon', BaseIcon);
  app.component('BaseBadge', BaseBadge);
  app.component('BaseSwitch', BaseSwitch);
  app.component('BaseFormAnswers', BaseFormAnswers);
  app.component('BaseEmptyState', BaseEmptyState);
  app.component('AppDownloadOverlay', AppDownloadOverlay);

  app.component('InlineSvg', InlineSvg);
  app.component('VueDatePicker', VueDatePicker);

  app.use(FloatingVue);
  app.use(Trix);

  app.directive('click-outside', ClickOutside);

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSuccess: (data, _variables, _context, mutation) => {
        if (data && mutation.options.mutationKey) {
          queryClient.setQueryData(mutation.options.mutationKey, data);
        } else {
          queryClient.invalidateQueries({
            queryKey: mutation.options.mutationKey,
          });
        }
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClient,
  };

  app.use(VueQueryPlugin, vueQueryPluginOptions);

  app.use(i18n);

  const pinia = createPinia();
  app.use(pinia);

  app.mount('#vue-app');

  return app;
});
