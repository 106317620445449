import { type MutationOptions } from '@tanstack/vue-query';

import type { ApiError } from '@/api';
import { createMagicCode } from '@/api/user';
import useReactiveMutation from '@/composables/useReactiveMutation';

type CreateMagicCodeMutationArgs = MutationOptions<void, ApiError, string>;

function useCreateMagicCodeMutation(options: CreateMagicCodeMutationArgs = {}) {
  return useReactiveMutation<void, ApiError, string>({
    ...options,
    mutationFn: (phoneNumber) => createMagicCode(phoneNumber).then(response => response.data),
  });
}

export { useCreateMagicCodeMutation };
