<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { BanknotesIcon, UserIcon } from '@heroicons/vue/24/outline';
import { inject } from 'vue';

import { currentUserKey } from '@/utils/keys';

import BaseAvatar from './base-avatar.vue';

const currentUser = inject(currentUserKey);
</script>

<template>
  <div>
    <Menu
      as="div"
      class="relative"
    >
      <div>
        <MenuButton class="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-primary-600">
          <base-avatar
            v-if="currentUser"
            :user="currentUser"
          />
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="absolute right-0 z-40 mt-2 flex origin-top-right flex-col overflow-hidden rounded-xl bg-white shadow-lg focus:outline-none">
          <div class="min-w-[200px] p-2">
            <MenuItem>
              <a
                href="/profile"
                class="flex w-full flex-row items-center gap-x-4 rounded-lg p-2 text-sm text-gray-700 hover:bg-gray-200"
              >
                <UserIcon class="h-5 w-5 shrink-0 stroke-current" />
                <span class="shrink-0 text-xs font-medium">
                  Mi perfil
                </span>
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/referrals/new"
                class="flex w-full flex-row items-center gap-x-4 rounded-lg p-2 text-sm text-gray-700 hover:bg-gray-200"
              >
                <BanknotesIcon class="h-5 w-5 shrink-0 stroke-current" />
                <span class="text-xs font-medium">
                  Invitar a cambio de dinero
                </span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
