<script setup lang="ts">
import { format } from 'date-fns';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import type { GroupConnections } from '@/api/group';
import { type GroupLoanApplication, type ConsolidatedLoan } from '@/api/groupLoanApplication';
import { useFetchGroupLoanApplication } from '@/api/groupLoanApplication/queries';
import Comments from '@/components/comments.vue';
import ConsolidatedGroupLoans from '@/components/reviews/consolidated-group-loans.vue';
import GroupLoanApplicationExternalConnections from
  '@/components/reviews/group-loan-application-external-connections.vue';
import GroupLoanApplicationHeader from '@/components/reviews/group-loan-application-header.vue';
import LoanApplicationPreApprovalSlideOver from
  '@/components/reviews/group-loan-application-pre-approval-slide-over.vue';
import GroupLoanApplicationsMemberLoanInfo from
  '@/components/reviews/group-loan-applications-member-loan-info.vue';
import groupMemberLoanApplicationButton from '@/components/reviews/group-member-loan-application-button.vue';

const props = defineProps<{
  groupLoanApplication: GroupLoanApplication;
  consolidatedLoans: ConsolidatedLoan[];
  previousGroupLoanApplications: GroupLoanApplication[];
  groupConnections: GroupConnections;
}>();

const groupLoanApplicationQ = useFetchGroupLoanApplication({
  id: props.groupLoanApplication.id,
  initialData: props.groupLoanApplication,
});

const groupMemberLoanApplications = computed(() => groupLoanApplicationQ.data?.memberLoanApplications);
const inactiveGroupMemberLoanApplications = computed(() => groupLoanApplicationQ.data?.inactiveMemberLoanApplications);
const allGroupMemberLoanApplications = computed(() => [
  ...(groupMemberLoanApplications.value || []),
  ...(inactiveGroupMemberLoanApplications.value || []),
]);

const currentMemberLoanApplicationId = ref(allGroupMemberLoanApplications.value[0].id);
const currentMemberLoanApplication = computed(() => allGroupMemberLoanApplications.value.find(
  (memberLoanApplication) => memberLoanApplication.id === currentMemberLoanApplicationId.value,
));

const preApprovalSlideOverOpen = ref(false);

function handleKeydown(event: KeyboardEvent) {
  if (event.shiftKey && event.key === 'M') {
    preApprovalSlideOverOpen.value = !preApprovalSlideOverOpen.value;
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>

<template>
  <div
    v-if="groupLoanApplicationQ.data"
    ref="containerRef"
    class="flex w-full flex-col gap-y-4"
  >
    <div class="flex flex-row gap-x-4 rounded-lg bg-white p-4">
      <div class="flex w-full flex-row gap-x-10">
        <group-loan-application-header
          :group-loan-application="groupLoanApplicationQ.data"
          :previous-group-loan-applications="previousGroupLoanApplications"
        />
        <div class="ml-auto w-[350px]">
          <comments
            title="Comentarios sobre el grupo"
            name="comment[body]"
            resource-type="GroupLoanApplication"
            :resource-id="groupLoanApplicationQ.data.id"
          />
        </div>
      </div>
    </div>
    <group-loan-application-external-connections
      :group-connections="groupConnections"
      :group="groupLoanApplication.group"
    />
    <consolidated-group-loans
      :group-loan-application="groupLoanApplicationQ.data"
      :consolidated-loans="consolidatedLoans"
    />
    <div class="flex flex-row gap-x-4">
      <div class="flex w-56 shrink-0 flex-col gap-y-4">
        <div
          v-if="groupLoanApplicationQ.data.group.pendingInvitations?.length > 0"
          class="flex flex-col rounded-lg bg-white p-2"
        >
          <span class="mb-4 text-xs text-gray-600">
            Invitaciones pendientes
          </span>
          <div class="flex flex-col gap-y-4">
            <div
              v-for="invitation in groupLoanApplicationQ.data.group.pendingInvitations"
              :key="invitation.id"
              class="flex flex-row justify-between gap-x-2"
            >
              <div class="flex flex-col gap-y-2">
                <span>
                  {{ invitation.invitee.firstName }}
                </span>
                <div class="flex flex-row items-center gap-x-2">
                  <span class="text-xs text-gray-700">
                    <b>ID:</b> {{ invitation.id }}
                  </span>
                  <span class="text-xs text-gray-700">
                    <b>Enviada:</b> {{ format(new Date(invitation.lastSentAt), 'dd/MM/yyyy HH:mm') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col rounded-lg bg-white p-2">
          <span class="mb-4 text-xs text-gray-600">
            Miembros del grupo
          </span>
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in groupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === currentMemberLoanApplicationId"
            @click="currentMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
          <group-member-loan-application-button
            v-for="groupMemberLoanApplication in inactiveGroupMemberLoanApplications"
            :key="groupMemberLoanApplication.id"
            :group-member-loan-application="groupMemberLoanApplication"
            :selected="groupMemberLoanApplication.id === currentMemberLoanApplicationId"
            @click="currentMemberLoanApplicationId = groupMemberLoanApplication.id"
          />
        </div>
      </div>
      <div
        v-if="currentMemberLoanApplication"
        class="flex w-full flex-col rounded-lg bg-white p-4"
      >
        <group-loan-applications-member-loan-info
          :group-member-loan-application="currentMemberLoanApplication"
        />
      </div>
    </div>
    <loan-application-pre-approval-slide-over
      :group-loan-application="groupLoanApplication"
      :open="preApprovalSlideOverOpen"
      @close="preApprovalSlideOverOpen = false"
    />
  </div>
</template>
